import { tryCatchHelper } from '../../../helpers';
import {
  deleteTiposRestauranteService,
  getAllTiposRestauranteService,
  getOneTiposRestauranteService,
  saveTiposRestauranteService,
} from '../../../services';
import {
  ACTIVO_TIPOS_RESTAURANTE,
  GETALL_TIPOS_RESTAURANTE,
  LOADING_GETALL_TIPOS_RESTAURANTE,
} from '../../../store/Types';

export const getAllTiposRestauranteAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_RESTAURANTE,
      payload: true,
    });
    const res = await getAllTiposRestauranteService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_RESTAURANTE,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_RESTAURANTE,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_RESTAURANTE,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_RESTAURANTE,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveTiposRestauranteAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposRestauranteService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de restaurante actualizado.'
        : 'El tipo de restaurante registrado.'
    );
    await getAllTiposRestauranteAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposRestauranteAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_RESTAURANTE,
    payload: data,
  });
};

export const getOneTiposRestauranteAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposRestauranteService(id);
    if (res.status === 200) {
      await setActivoTiposRestauranteAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposRestauranteAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposRestauranteService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de restaurante eliminado.');
      await setActivoTiposRestauranteAction(dispatch, null);
      await getAllTiposRestauranteAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
