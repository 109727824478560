import { tryCatchHelper } from '../../../helpers';
import {
  deleteZonasRestauranteService,
  getAllZonasRestauranteService,
  getOneZonasRestauranteService,
  saveZonasRestauranteService,
} from '../../../services';
import {
  ACTIVO_ZONAS_RESTAURANTE,
  GETALL_ZONAS_RESTAURANTE,
  LOADING_GETALL_ZONAS_RESTAURANTE,
} from '../../../store/Types';

export const getAllZonasRestauranteAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_ZONAS_RESTAURANTE,
      payload: true,
    });
    const res = await getAllZonasRestauranteService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_ZONAS_RESTAURANTE,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_ZONAS_RESTAURANTE,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_ZONAS_RESTAURANTE,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_ZONAS_RESTAURANTE,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveZonasRestauranteAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveZonasRestauranteService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de restaurante actualizado.'
        : 'El tipo de restaurante registrado.'
    );
    await getAllZonasRestauranteAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoZonasRestauranteAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_ZONAS_RESTAURANTE,
    payload: data,
  });
};

export const getOneZonasRestauranteAction = async (dispatch, id) => {
  try {
    const res = await getOneZonasRestauranteService(id);
    if (res.status === 200) {
      await setActivoZonasRestauranteAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteZonasRestauranteAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteZonasRestauranteService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Zona de restaurante eliminado.');
      await setActivoZonasRestauranteAction(dispatch, null);
      await getAllZonasRestauranteAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
