import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import * as ROUTES from '../../../router/Routes';
import { GETALL_SUBAREAS, PARAMS_AREAS } from '../../../store/Types';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { checkModuleActionAccess, isManagerUserCode } from '../../modulos/modules.helpers';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteAreasAction,
  getAllAreasAction,
  saveAreasAction,
  setActivoAreasAction,
} from '../AreasAction';
import { areas } from '../AreasService';
import ExpandeRowAreas from './ExpandedRowAreas';

const ListadoAreas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  
  const isRead = isRoot || checkModuleActionAccess('areas', 'read', loginStore);
  const isDelete = isRoot || checkModuleActionAccess('areas', 'delete', loginStore);
  const isUpdate = isRoot || checkModuleActionAccess('areas', 'update', loginStore);

  const payload = {
    ...areasStore.params,
    entityId: entidadesStore.activo.id,
  };

  const action = {
    read: checkModuleActionAccess('areas', 'read', loginStore),
    create: checkModuleActionAccess('areas', 'create', loginStore),
    delete: checkModuleActionAccess('areas', 'delete', loginStore),
    update: checkModuleActionAccess('areas', 'update', loginStore),
  }

  React.useEffect(async () => {
    dispatch({ type: PARAMS_AREAS, payload });
    if (areasStore.all && areasStore.all.length === 0) {
      await getAllAreasAction(dispatch, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const _handleInfoClick = (id) => {
    const area = areasStore.all.find((el) => el.id === id);
    setActivoAreasAction(dispatch, area);
    dispatch({ type: GETALL_SUBAREAS, payload: { data: [] } });
    history.push(ROUTES.areasInfo.to);
  };

  const _handleEditClick = async (id) => {
    const area = areasStore.all.find((el) => el.id === id);
    await setActivoAreasAction(dispatch, area);
    setOpenModalForm(true);
  };

  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);
  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteAreasAction(
      { dispatch, addNotification },
      id,
      areasStore.params
    );
    setIsDeleteOpen(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveAreasAction(
      { dispatch, addNotification },
      id,
      {
        active: !areasStore.activo.active,
      },
      areasStore.params
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  console.log(isRead);

  React.useEffect(() => {
    setRows([]);
    areasStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.CATEGORY]: el.category.name ?? 'N/A',
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [areasStore.all]);

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.IMAGE_URL,
      label: 'Imagen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!isRead) return value;
          return (
            <NameComponent
              onClick={action.read ? () => _handleInfoClick(tableMeta.rowData[0]) : null}
            >
              {' '}
              {value}{' '}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.CATEGORY,
      label: 'Categoría',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                {
                  action.read && (
                    <Tooltip title="Información">
                      <IconButton
                        aria-label="edit"
                        size="small"
                        color="primary"
                        onClick={() => _handleInfoClick(tableMeta.rowData[0])}
                      >
                        <VisibilityIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )
                }
                {(action.delete || action.update) && (
                  <ActionsList
                    id={tableMeta.rowData[0]}
                    onEdit={action.update ? () => _handleEditClick(tableMeta.rowData[0]) : null}
                    onDelete={action.delete ? async () => {
                      await setActivoAreasAction(dispatch, value);
                      setIsDeleteOpen(true);
                    } : null}
                    onToggleActive={action.update ? async () => {
                      await setActivoAreasAction(dispatch, value);
                      setIsToggleActive(true);
                    } : null}
                    active={value.active}
                  />
                )}
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowAreas
          data={areasStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },

    onDownload: () => {
      const url = `${areas}/export`;
      const name = 'areas';
      const params = payload;
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={areasStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {areasStore.activo ? (
        <Modal
          id="modalEliminarArea"
          title={`Eliminar ${areasStore.activo.name}`}
          open={isDeleteOpen}
          maxWidth="sm"
          onClose={() => setIsDeleteOpen(!isDeleteOpen)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta área?
              <p>
                <strong>{areasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(areasStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDeleteOpen(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {areasStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${areasStore.activo.active ? 'Bloquear' : 'Activar'} ${
            areasStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                areasStore.activo.active ? 'Bloquear' : 'Activar'
              } esta área?`}
              <p>
                <strong>{areasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(areasStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoAreas;
