import axios from 'axios';
import { baseApi, token } from '../../services/Apis';
import { serialize } from 'object-to-formdata';

export const areas = `${baseApi}/areas`;

export const getAllAreasService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(areas, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveAreasService = async (id, data) => {

  const isFile = data.image || data.mapImage;
  if (!data.extras?.length) {
    delete data.extras;
  }
  if (!!isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
      indices: true,
    })
  }

  let res;
  if (id) {
    res = await axios.put(`${areas}/${id}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  } else {
    res = await axios.post(areas, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  }
  return res.data;
};

export const getOneAreasService = async (id, params) => {
  const res = await axios.get(`${areas}/${id}`, {
    headers: await token({}),
    params,
  });
  return res;
};

export const deleteAreasService = async (id) => {
  const res = await axios.delete(`${areas}/${id}`, {
    headers: await token({}),
  });
  return res;
};
