import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import EntDestacadasview from './EntDestacadasView';

const EntDestacadasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.entDestacadas} routes={[]}>
      <EntDestacadasview />
    </ContainerTemplate>
  );
};

export default EntDestacadasModule;
