import { ADMIN, SU } from '../../constants/ConfigConst';
import { MANAGER } from '../../constants/FieldsConst';
import { banks } from '../../router/Routes';

const rename = {
  contact: 'Contacts',
  accessDoor: 'Control',
  document: 'Documents',
  payment: 'Payments',
  reservation: 'Reserves',
  invitation: 'Invitations',
  delivery: 'Delivery',
  user: 'Users',
  code: 'Codes',
  classes: 'Classes',
  event: 'Events',
  areas: 'Areas',
  subareas: 'Subareas',
  accessLogs: 'AccessLogs',
  banks: 'Banks',
  events: 'Events',
};

export const checkModuleAccess = (module, loginStore) => {
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  if (isRoot) return true;
  const modules = loginStore.modules;
  
  const moduleAccess = modules?.find((m) => m.name === rename[module]);
  return moduleAccess && moduleAccess.active;
};

export const checkModuleActionAccess = (module, action, loginStore) => {
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  // console.log(loginStore);
  const isAdmin = loginStore.roles.indexOf('admin') !== -1 && loginStore.user.userCodes.some((uc) => uc.userType === 'admin');
  if (isRoot || isAdmin) return true;
  console.log('asiduhuasd')
  const modules = loginStore.modules;

  const moduleAccess = modules?.find((m) => m.name === rename[module]);
  return moduleAccess && moduleAccess[action];
}

export const checkRootAccess = (loginStore) => {
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  if (!isRoot) {
    window.location.href = '/dashboard';
  }
};

export const isManagerUserCode = (userCodes, entityId) => {
  const userCode = userCodes.find(
    (userCode) => userCode.code.entity.id === entityId
  );
  return userCode && userCode.userType === MANAGER;
};

export const isAdminUserCode = (userCodes, entityId) => {
  const userCode = userCodes.find(
    (userCode) => userCode.code.entity.id === entityId
  );
  return userCode && userCode.userType === ADMIN;
};
