import { Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import {
  getAllEntDestacadasAction,
  setActivoEntDestacadasAction,
} from './EntDestacadasAction';
import FormEntDestacadas from './components/FormEntDestacadas';
import ListadoEntDestacadas from './components/ListadoEntDestacadas';
import { Modal } from '../../components';

const EntDestacadasView = () => {
  const dispatch = Redux.useDispatch();
  const entDestacadasStore = Redux.useSelector((state) => state.entDestacadas);
  const loginStore = Redux.useSelector((state) => state.login);
  const [openModalForm, setOpenModalForm] = React.useState(false);

  const accessName = 'banks';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoEntDestacadasAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {entDestacadasStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoEntDestacadasAction(dispatch, null);
              await getAllEntDestacadasAction(
                dispatch,
                entDestacadasStore.params
              );
            }}
            white
            disabled={entDestacadasStore.loadingGetAll}
            text={
              entDestacadasStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoEntDestacadas setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      
      <Modal
        id="modalFormEntDestacadas"
        title={`${entDestacadasStore.activo ? 'Editar' : 'Nueva'} entidad destacada`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        <FormEntDestacadas
          activo={entDestacadasStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default EntDestacadasView;

export const mesesArray = [
  { nombre: 'Enero', valor: '01' },
  { nombre: 'Febrero', valor: '02' },
  { nombre: 'Marzo', valor: '03' },
  { nombre: 'Abril', valor: '04' },
  { nombre: 'Mayo', valor: '05' },
  { nombre: 'Junio', valor: '06' },
  { nombre: 'Julio', valor: '07' },
  { nombre: 'Agosto', valor: '08' },
  { nombre: 'Septiembre', valor: '09' },
  { nombre: 'Octubre', valor: '10' },
  { nombre: 'Noviembre', valor: '11' },
  { nombre: 'Diciembre', valor: '12' },
];
