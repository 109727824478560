import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../../components/Loading';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat, getDateTimezone } from '../../../../helpers';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { getSearchUsersAction } from '../../../usuarios/UsuariosAction';
import { saveClasesDocentesAction } from '../../clases_docentes/ClasesDocentesAction';

import ButtonFileMultipleImages from '../../../../components/FieldFileMultilpleImages';
import { baseApi, token } from '../../../../services/Apis';
import FormClasesProfesorSchedule from './FormClasesProfesorSchedule';

const getScheduleChanges = (schedules) => {
  try {
    if (schedules && schedules.length > 0) {
      return schedules.map(({ id, blocks, ...s }) => ({
        ...s,
        blocks: blocks.map(({ id, ...b }) => b),
      }));
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const FormClasesProfesor = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const clasesStore = Redux.useSelector((state) => state.clases);
  const clasesDocentesStore = Redux.useSelector(
    (state) => state.clasesDocentes
  );

  const defaultSchedules = Array.from({ length: 7 }, (_, i) => {
    return {
      day: i,
      blocks: [
        {
          start: '08:00:00',
          end: '17:00:00',
          interval: 30,
          price: 0,
        },
      ],
    };
  });

  const timezone = entidadesStore.activo
    ? entidadesStore.activo.country.timezone
    : 'America/Caracas';

  const [dataForm, setDataForm] = React.useState({
    [CONST.USER_CODE_ID]: '',
    [CONST.ACTIVE]: true,
    [CONST.SCHEDULES]: [],
    [CONST.DESCRIPTION]: '',
    [CONST.AGES]: '',
    [CONST.NUMBER_SPOTS]: 5,
    [CONST.PRICE]: '',
    levelId: '',
    [CONST.START_DATE]: dateformat(null, {
      full: true,
    }),
    [CONST.END_DATE]: dateformat(null, {
      full: true,
    }),
    spotsShape: 'square',
    spotsPerRow: 8,
    deletedAboutImages: [],
    newAboutImages: [],
    aboutImages: [],
    schedules: clasesDocentesStore.activo
      ? getScheduleChanges(clasesDocentesStore.activo.schedules)
      : defaultSchedules,
    maxParticipants: 1,
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.USER_CODE_ID]: false,

    [CONST.NUMBER_SPOTS]: false,
    [CONST.PRICE]: false,
    levelId: false,
    spotsShape: false,
    maxParticipants: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  const [dataUsers, setDataUsers] = React.useState([]);
  const [loadingSelectUser, setLoadingDataUser] = React.useState(false);

  const [teacherSelect, setTeacherSelect] = React.useState(null);
  const [levels, setLevels] = React.useState([]);

  // const selectType = dataForm[CONST.CLASS_TYPE_ID] ? types.find((item) => item.id === dataForm[CONST.CLASS_TYPE_ID]) : null;

  React.useEffect(() => {
    doSearchUser('');
    if (clasesDocentesStore.activo === null) return;
    let data = {
      id: clasesDocentesStore.activo.id,
    };
    Object.keys(dataForm).map(
      (key) => (data[key] = clasesDocentesStore.activo[key])
    );

    data = {
      ...data,
      [CONST.USER_CODE_ID]: clasesDocentesStore.activo.userCode.id,
      levelId: clasesDocentesStore.activo.level?.id,
      deletedAboutImages: [],
    };

    const userCode = { ...clasesDocentesStore.activo.userCode };
    const teacherUser = userCode.user;
    delete userCode.user;
    teacherUser.userCodes = [userCode];

    setTeacherSelect(teacherUser);
    _handleSetDataField(
      CONST.USER_CODE_ID,
      clasesDocentesStore.activo.userCode.id
    );

    setTimeout(() => {
      setDataForm(data);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      axios
        .get(`${baseApi}/class-levels`, {
          headers: await token({}),
          params: {
            entityId: entidadesStore.activo.id,
          },
        })
        .then((res) => {
          if (!dataError.levelId) {
            _handleSetDataField('levelId', res.data.data[0].id);
          }
          setLevels(res.data.data);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSetDataField = (key, value) =>
    setDataForm({ ...dataForm, [key]: value });

  React.useEffect(() => {
    setDataUsers([]);
    if (usuariosStore.searchUsers && usuariosStore.searchUsers.length > 0) {
      usuariosStore.searchUsers.map((user) => {
        return setDataUsers((old) => [
          ...old,
          {
            [CONST.ID]: user.id,
            [CONST.NAME]: `${user.profile.firstName} ${user.profile.lastName}`,
          },
        ]);
      });
    }
  }, [usuariosStore.searchUsers]);

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = dataForm[key];
      const valid = val === null || val === '';
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    if (valid) {
      const data = {
        ...dataForm,
        [CONST.START_DATE]: getDateTimezone(
          moment(dataForm.startDate).startOf('day'),
          timezone
        ),
        [CONST.END_DATE]: getDateTimezone(
          moment(dataForm.endDate).startOf('day'),
          timezone
        ),
        schedules: getScheduleChanges(dataForm.schedules),
      };

      if (!clasesDocentesStore.activo) data.classId = clasesStore.activo.id;

      setLoadingSave(true);
      let res = await saveClasesDocentesAction(
        { dispatch, addNotification },
        clasesDocentesStore.activo === null
          ? null
          : clasesDocentesStore.activo.id,
        data,
        {
          entityId: entidadesStore.activo.id,
          classId: clasesStore.activo.id,
        }
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  var delayTimerUser;
  function doSearchUser(text) {
    clearTimeout(delayTimerUser);
    setLoadingDataUser(true);
    delayTimerUser = setTimeout(async () => {
      await getSearchUsersAction(
        dispatch,
        entidadesStore.activo.id,
        text,
        'teacher'
      );
      setLoadingDataUser(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  // const tabPanel1 = <TabPanel value={1}>
  const tabPanel1 = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        {!dataForm.id && (
          <Autocomplete
            freeSolo
            id={'selectCode'}
            disableClearable
            options={dataUsers.map((option) => option.name)}
            size="small"
            loading={loadingSelectUser}
            onChange={(event, newValue) => {
              const item = usuariosStore.searchUsers.find(
                (a) =>
                  `${a.profile.firstName} ${a.profile.lastName}` === newValue
              );
              _handleSetDataField(CONST.USER_CODE_ID, item.userCodes[0].id);
              setTeacherSelect(item);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Buscar profesor`}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  endAdornment: (
                    <React.Fragment>
                      {loadingSelectUser && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}

        {teacherSelect && (
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Avatar
              alt={teacherSelect.profile.firstName}
              sx={{ width: 100, height: 100, mr: 1 }}
              src={teacherSelect.userCodes[0].avatar}
            />
            <Card sx={{ p: 1, mt: 1, width: '100%' }}>
              <Typography variant="h6" fontWeight="bold">
                {teacherSelect.profile.identificationNumber}{' '}
                {teacherSelect.profile.firstName}{' '}
                {teacherSelect.profile.lastName}
              </Typography>
              <Divider sx={{ mb: 1 }} />
              {teacherSelect.userCodes.map((item, index) => (
                <Chip
                  label={`${item.userType} - ${item.code.code}`}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: MORADO_COLOR,
                      color: 'white',
                    },
                  }}
                />
              ))}
            </Card>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Fecha de inicio"
          value={dataForm.startDate}
          onChange={(newValue) => {
            _handleSetDataField(CONST.START_DATE, newValue.toDate());
          }}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Fecha de finalización"
          value={dataForm.endDate}
          onChange={(newValue) =>
            _handleSetDataField(CONST.END_DATE, newValue.toDate())
          }
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.AGES}
          label="Edades permitidas"
          value={dataForm.ages}
          onChange={(e) => _handleSetDataField(CONST.AGES, e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.PRICE}
          type="number"
          label="Costo del servicio"
          value={dataForm.price}
          onChange={(e) => _handleSetDataField(CONST.PRICE, e.target.value)}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="levelId">Nivel de curso</InputLabel>
          <Select
            labelId="levelId"
            size="small"
            label="Nivel de curso"
            value={dataForm.levelId}
            onChange={(e) => _handleSetDataField('levelId', e.target.value)}
            id={'levelId'}
          >
            {levels.map((item, index) => (
              <MenuItem key={`item_${index}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="label_estatus">Estatus</InputLabel>
          <Select
            labelId="label_estatus"
            size="small"
            value={dataForm.active}
            label="Estatus"
            onChange={(e) => _handleSetDataField(CONST.ACTIVE, e.target.value)}
            id={CONST.ACTIVE}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Bloqueado</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          type="number"
          value={dataForm.numberSpots}
          label="Puestos disponibles"
          onChange={(e) =>
            _handleSetDataField(CONST.NUMBER_SPOTS, e.target.value)
          }
          id={CONST.NUMBER_SPOTS}
          InputProps={{
            inputProps: { min: 5 },
          }}
          helperText={dataError.numberSpots ? validFielddHelper(1) : null}
          error={dataError.numberSpots}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          type="number"
          value={dataForm.maxParticipants}
          label="Limite de participantes"
          onChange={(e) =>
            _handleSetDataField('maxParticipants', e.target.value)
          }
          id={'maxParticipants'}
          InputProps={{
            inputProps: { min: 1 },
          }}
          helperText={dataError.maxParticipants ? validFielddHelper(1) : null}
          error={dataError.maxParticipants}
        />
      </Grid>

      {clasesStore.activo?.type === 'reservable' && (
        <>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel id="spot_shape">Forma de los puestos</InputLabel>
              <Select
                labelId="spot_shape"
                size="small"
                label="Forma de los puestos"
                value={dataForm.spotsShape}
                onChange={(e) =>
                  _handleSetDataField('spotsShape', e.target.value)
                }
                id={'spotsShape'}
              >
                <MenuItem value={'square'}>Cuadrada</MenuItem>
                <MenuItem value={'circle'}>Redonda</MenuItem>
                <MenuItem value={'triangle'}>Triangular</MenuItem>
                {clasesDocentesStore.activo && (
                  <MenuItem value={'custom'}>Personalizada</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {['square', 'triangle', 'circle'].includes(dataForm.spotsShape) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={dataForm.spotsPerRow}
                label="Puestos por fila"
                onChange={(e) =>
                  _handleSetDataField('spotsPerRow', e.target.value)
                }
                id={'spotsPerRow'}
                InputProps={{
                  inputProps: { min: 2 },
                }}
              />
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.DESCRIPTION}
          label="Descripción"
          value={dataForm.description}
          onChange={(e) =>
            _handleSetDataField(CONST.DESCRIPTION, e.target.value)
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <ButtonFileMultipleImages
          images={dataForm.aboutImages}
          onChange={(files) => {
            setDataForm({ ...dataForm, newAboutImages: files });
          }}
          onDelete={(image) => {
            dataForm.deletedAboutImages.push(image);
            setDataForm({
              ...dataForm,
            });
          }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormClasesProfesorSchedule
          edit
          onChange={(res) => {
            const schedules = res.map((r) => ({
              ...r,
              blocks: r.blocks.sort((a, b) => a.start.localeCompare(b.start)),
            }));
            setDataForm({ ...dataForm, schedules });
          }}
          data={
            dataForm.schedules
              ? getScheduleChanges(dataForm.schedules)
              : undefined
          }
        />
      </Grid>
    </Grid>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="form" sx={{}}>
        <LoadingComponent
          isLoading={loadingSave}
          text={clasesStore.activo ? 'Actualizando...' : 'Guardando...'}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                  Información
                </Typography>
                <Divider sx={{ mb: 1 }}></Divider>
                {tabPanel1}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} align="center" sx={{ m: 2 }}>
            <BotonNuevoComponent
              click={handleSubmit}
              text="GUARDAR"
              disabled={loadingSave}
              morado
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

FormClasesProfesor.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormClasesProfesor;
