import axios from 'axios';
import { baseApi, token } from '../../services/Apis';
import { serialize } from 'object-to-formdata';

export const entDestacadasApi = `${baseApi}/featured-entities`;

export const getAllEntDestacadasService = async (params) => {
  const res = await axios.get(entDestacadasApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveEntDestacadasService = async (id, data) => {

  const isFile = data.banner;

  if (isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
      indices: true,
    })
  }
  
  let res;
  if (id) {
    res = await axios.put(`${entDestacadasApi}/${id}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  } else {
    res = await axios.post(`${entDestacadasApi}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  }

  return res;
};

export const deleteEntDestacadasService = async (id) => {
  const res = await axios.delete(`${entDestacadasApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
