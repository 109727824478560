import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import { TiposEntidadesField } from '../../../components';
import LoadingComponent from '../../../components/Loading';
import PaisField from '../../../components/fields/PaisField';
import PhoneNumberField from '../../../components/fields/PhoneNumberField';
import { ADMIN, SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { STATUS_ENUM } from '../../../constants/enumsConst';
import { dateformat } from '../../../helpers';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { baseApi, token } from '../../../services/Apis';
import { CorreoField } from '../../usuarios/components/FieldsUsuarios';
import { saveEntidadesAction } from '../EntidadesAction';
import BotonNuevoComponent from './BotonNuevo';
import FormDatosEntidad from './FormDatosEntidad';
import FormImagenesComponents from './FormImagenes';
import FormMetodosPago from './FormMetodosPago';
import FormEntityRoot from './FormEntityRoot';
import FormEntityMorosity from './FormEntityMorosity';
import useFetch from 'http-react';

// const getCountries = async () => {
//   const { data } = await axios.get(`${baseApi}/countries`, {
//     headers: await token({}),
//     params: { active: true },
//   });
//   return data.data;
// };

const FormEntidades = ({ openModal }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  // const [countries, setCountries] = React.useState([]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const countries = await getCountries();
  //     setCountries(countries);
  //   };

  //   fetchData();
  // }, []);

  let activo = entidadesStore.activo;

  const [tab, setTab] = React.useState(1);
  const [entidad, setEntidad] = React.useState({
    [CONST.NAME]: '',
    [CONST.ABBREV]: '',
    [CONST.ADDRESS]: '',
    [CONST.PHONE]: '',
    [CONST.EMAIL]: '',
    [CONST.RIF]: '',
    [CONST.MOROSITY_TIME]: '4',
    [CONST.OPENING_TIME]: '00:00:59',
    [CONST.CLOSING_TIME]: '23:59:59',
    [CONST.ENTITY_TYPE_ID]: 1,
    [CONST.COUNTRY_ID]: null,
    [CONST.STATUS]: STATUS_ENUM.ACTIVE,
    [CONST.AVATAR_UPDATABLE]: true,
    [CONST.IS_PUBLIC]: false,
    [CONST.OWNER_UPDATABLE_USER_INFO]: true,
    [CONST.ALLOW_ENTRY_CONFIRMATION]: false,
    [CONST.ENTRY_CONFIRMATION_TIME_LIMIT]: 60,
    iconColor: '#000000',
    isMinorRegistrationAppAllowed: false,
    deliveryAccessLimit: 2,
    stateId: null,
    cityId: null,
    notificationEmail: null,
  });
  const [dataRequired, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.ABBREV]: false,
    [CONST.ADDRESS]: false,
    [CONST.PHONE]: false,
    [CONST.EMAIL]: false,
    [CONST.RIF]: false,
    [CONST.MOROSITY_TIME]: false,
    [CONST.OPENING_TIME]: false,
    [CONST.CLOSING_TIME]: false,
    [CONST.COUNTRY_ID]: false,
    stateId: false,
    cityId: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    const fetchData = () => {
      if (activo === null) return;
      let data = {};
      Object.keys(entidad).map((key) => (data[key] = activo[key]));
      data = {
        ...data,
        [CONST.COUNTRY_ID]: activo.country.id,
        [CONST.ENTITY_TYPE_ID]: activo.entityType.id,
        [CONST.OPENING_TIME]: dateformat(activo.openingTime, {
          isHHmm: true,
          complete: true,
        }),
        [CONST.CLOSING_TIME]: dateformat(activo.closingTime, {
          isHHmm: true,
          complete: true,
        }),
        [CONST.OWNER_UPDATABLE_USER_INFO]: activo.ownerUpdatableUserInfo,
        [CONST.AVATAR_UPDATABLE]: activo.avatarUpdatable,
        [CONST.IS_PUBLIC]: activo.isPublic,
        [CONST.ALLOW_CREATE_EVENTS]: activo.allowCreateEvents,
        iconColor: activo.iconColor,
        stateId: activo.state.id,
        cityId: activo.city.id,
        notificationEmail: activo.notificationEmail,  
      };
      setEntidad(data);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const { data: countriesData, loading: loadingCountries } = useFetch(
    `${baseApi}/countries/short`,
    {
      headers: token({}),
      query: { active: true },
    }
  );
  const countries = countriesData && !countriesData.errors ? countriesData : [];

  const { data: statesData, loading: loadingStates } = useFetch(
    `${baseApi}/states/short`,
    {
      headers: token({}),
      query: {
        countryId: entidad.countryId
      },
    }
  );
  const states = statesData && !statesData.errors ? statesData : [];

  const { data: citiesData, loading: loadingCities } = useFetch(
    `${baseApi}/cities/short`,
    {
      headers: token({}),
      query: {
        stateId: entidad.stateId,
      },
    }
  );
  const cities = citiesData && !citiesData.errors ? citiesData : [];

  const _handleSetDataField = (key, value) =>
    setEntidad({ ...entidad, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataRequired).forEach((key) => {
      const val = entidad[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();
    if (valid) {
      let data = {
        ...entidad,
        [CONST.MOROSITY_TIME]: Number(entidad.morosityTime),
        [CONST.OPENING_TIME]: dateformat(entidad.openingTime, {
          isHHmmss: true,
          complete: true,
        }),
        [CONST.CLOSING_TIME]: dateformat(entidad.closingTime, {
          isHHmmss: true,
          complete: true,
        }),
        [CONST.ENTITY_TYPE_ID]: entidad.entityTypeId,
        [CONST.COUNTRY_ID]: entidad.countryId,
      };
      setLoadingSave(true);
      let res = await saveEntidadesAction(
        { dispatch, addNotification },
        data,
        {
          id: activo ? activo.id : null,
          isSU: loginStore.rol === SU,
        },
        entidadesStore.params
      );
      if (openModal) {
        openModal(!res);
      }
      setLoadingSave(false);
    }
  };

  const bodyPanel1 = (
    <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.NAME}
          required
          label="Nombre"
          value={entidad.name}
          onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
          helperText={dataRequired.name ? validFielddHelper(1) : null}
          error={dataRequired.name}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.ABBREV}
          required
          label="Abreviación"
          value={entidad.abbrev}
          onChange={(e) => _handleSetDataField(CONST.ABBREV, e.target.value)}
          helperText={dataRequired.abbrev ? validFielddHelper(1) : null}
          error={dataRequired.abbrev}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                value={entidad.isPublic}
                checked={entidad.isPublic}
                disabled={!!activo?.id}
                onChange={(e) => {
                  const newEntidad = {
                    ...entidad,
                    isPublic: e.target.checked,
                  };
                  setEntidad(newEntidad);
                }}
                name={CONST.IS_PUBLIC}
              />
            }
            label="Es público"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneNumberField
          value={entidad.phone}
          onChange={(value) => _handleSetDataField(CONST.PHONE, value)}
          error={dataRequired.phone}
          fieldName={CONST.PHONE}
          required={true}
          dataError={dataRequired}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CorreoField
          v={entidad.email}
          on={(e) => _handleSetDataField(CONST.EMAIL, e.target.value)}
          e={dataRequired.email}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.RIF}
          required
          label="RIF"
          value={entidad.rif}
          onChange={(e) => _handleSetDataField(CONST.RIF, e.target.value)}
          helperText={dataRequired.rif ? validFielddHelper(1) : null}
          error={dataRequired.rif}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TiposEntidadesField
          value={entidad.entityTypeId}
          onChange={(e) =>
            _handleSetDataField(CONST.ENTITY_TYPE_ID, e.target.value)
          }
          error={dataRequired.entityTypeId}
          disabled={loginStore.rol === ADMIN}
          isPublic={entidad.isPublic}
        />
      </Grid>

      {/* // */}
      {/* <Grid item xs={12} md={6}>
        <PaisField
          value={entidad.countryId}
          onChange={(e) =>
            _handleSetDataField(CONST.COUNTRY_ID, e.target.value)
          }
          error={dataRequired.countryId}
          disabled={loginStore.rol === ADMIN}
          paises={countries}
        />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <Typography variant="body">Pais</Typography>
        <FormControl fullWidth>
          <Select
            labelId='countryId'
            size="small"
            value={entidad.countryId}
            onChange={(e) => {
              setEntidad({
                ...entidad,
                countryId: e.target.value,
                stateId: null,
                cityId: null,
              });
            }}
            id='countryId'
            disabled={loginStore.rol === ADMIN}
          >
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Typography variant="body">Estado</Typography>
        <FormControl fullWidth>
          <Select
            labelId='stateId'
            size="small"
            value={entidad.stateId}
            onChange={(e) => {
              setEntidad({
                ...entidad,
                stateId: e.target.value,
                cityId: null,
              });
            }}
            id='stateId'
            disabled={loginStore.rol === ADMIN}
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      
      
      <Grid item xs={12} md={6}>
        <Typography variant="body">Ciudad</Typography>
        <FormControl fullWidth>
          <Select
            labelId='cityId'
            size="small"
            value={entidad.cityId}
            onChange={(e) => _handleSetDataField('cityId', e.target.value)}
            id='cityId'
            disabled={loginStore.rol === ADMIN}
          >
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <Typography variant="body">Dirección</Typography>
          <TextField
            fullWidth
            size="small"
            id={CONST.ADDRESS}
            required
            label="Dirección"
          value={entidad.address}
          onChange={(e) => _handleSetDataField(CONST.ADDRESS, e.target.value)}
          helperText={dataRequired.address ? validFielddHelper(1) : null}
            error={dataRequired.address}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <Typography variant="body">Hora de apertura</Typography>
          <TextField
            size="small"
            type="time"
            value={entidad.openingTime}
            onChange={(e) =>
              _handleSetDataField(CONST.OPENING_TIME, e.target.value)
            }
            id={CONST.OPENING_TIME}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <Typography variant="body">Hora de cierre</Typography>
          <TextField
            size="small"
            type="time"
            value={entidad.closingTime}
            onChange={(e) =>
              _handleSetDataField(CONST.CLOSING_TIME, e.target.value)
            }
            id={CONST.CLOSING_TIME}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body">Tiempo de morosidad</Typography>
        <TextField
          fullWidth
          size="small"
          type="number"

          InputProps={{
            inputProps: { min: 0 },
          }}
          id={CONST.MOROSITY_TIME}
          // disabled={loginStore.rol === ADMIN}
          value={entidad.morosityTime}
          onChange={(e) =>
            _handleSetDataField(CONST.MOROSITY_TIME, e.target.value)
          }
          helperText={dataRequired.morosityTime ? validFielddHelper(1) : null}
          error={dataRequired.morosityTime}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body">Estatus</Typography>
        <FormControl fullWidth>
          <Select
            labelId={CONST.STATUS}
            size="small"
            value={entidad.status}
            onChange={(e) => _handleSetDataField(CONST.STATUS, e.target.value)}
            id={CONST.STATUS}
            disabled={loginStore.rol === ADMIN}
          >
            <MenuItem value="ACTIVE">Activo</MenuItem>
            <MenuItem value="BLOCKED">Bloqueado</MenuItem>
            <MenuItem value="DEBT">Deudor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      
      
      <Grid item xs={12} md={6}>
        <Typography variant="body">-</Typography>
        <FormControl fullWidth>
          <TextField
            fullWidth
            size="small"
            id="iconColor"
            required
            label="Color de iconos"
            value={entidad.iconColor}
            type="color"
            min={1}
            onChange={(e) => {
              _handleSetDataField('iconColor', e.target.value);
            }}
          />
        </FormControl>
      </Grid>

      
      <Grid item xs={12} md={6}>
        <Typography variant="body">Límite de acceso a delivery</Typography>
        <FormControl fullWidth>
          <TextField
            size="small"
            type="number"
            value={entidad.deliveryAccessLimit}
            onChange={(e) =>
              _handleSetDataField(
                'deliveryAccessLimit',
                e.target.value
              )
            }
            id="deliveryAccessLimit"
            InputProps={{
              inputProps: { min: 0 },
            }}
            helperText={
              dataRequired.deliveryAccessLimit ? validFielddHelper(1) : null
            }
            error={dataRequired.deliveryAccessLimit}
          />
        </FormControl>
      </Grid>

      {![2, 3].includes(entidad.entityTypeId) && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel

              control={
                <Checkbox
                  value={entidad.ownerUpdatableUserInfo}
                  checked={entidad.ownerUpdatableUserInfo}
                  onChange={(e) => {
                    const newEntidad = {
                      ...entidad,
                      ownerUpdatableUserInfo: e.target.checked,
                    };
                    if (!e.target.checked) {
                      newEntidad.avatarUpdatable = false;
                    }
                    setEntidad(newEntidad);
                  }}
                  name={CONST.OWNER_UPDATABLE_USER_INFO}
                />
              }
              label="Propietario puede actualizar información del usuario"
            />
          </FormControl>
        </Grid>
      )}
      {entidad.entityTypeId !== 2 && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entidad.avatarUpdatable}
                  value={entidad.avatarUpdatable}
                  disabled={!entidad.ownerUpdatableUserInfo}
                  onChange={(e) =>
                    _handleSetDataField(
                      CONST.AVATAR_UPDATABLE,
                      e.target.checked
                    )
                  }
                  name={CONST.AVATAR_UPDATABLE}
                />
              }
              label="Avatar actualizable"
            />
          </FormControl>
        </Grid>
      )}

      {entidad.entityTypeId !== 2 && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entidad.allowEntryConfirmation}
                  value={entidad.allowEntryConfirmation}
                  onChange={(e) =>
                    _handleSetDataField(
                      CONST.ALLOW_ENTRY_CONFIRMATION,
                      e.target.checked
                    )
                  }
                  name={CONST.ALLOW_ENTRY_CONFIRMATION}
                />
              }
              label="Permitir confirmación de entrada"
            />
          </FormControl>
        </Grid>
      )}

      {entidad.allowEntryConfirmation && (
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            size="small"
            id={CONST.ENTRY_CONFIRMATION_TIME_LIMIT}
            required
            label="Tiempo de confirmación de entrada"
            value={entidad.entryConfirmationTimeLimit}
            inputProps={{
              min: 10,
            }}
            onChange={(e) =>
              _handleSetDataField(
                CONST.ENTRY_CONFIRMATION_TIME_LIMIT,
                e.target.value
              )
            }
            helperText={
              dataRequired.entryConfirmationTimeLimit
                ? validFielddHelper(1)
                : null
            }
            error={dataRequired.entryConfirmationTimeLimit}
          />
        </Grid>
      )}

      {entidadesStore.activo?.isMinorRegistrationAllowed && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entidad.isMinorRegistrationAppAllowed}
                  value={entidad.isMinorRegistrationAppAllowed}
                  onChange={(e) =>
                    _handleSetDataField(
                      'isMinorRegistrationAppAllowed',
                      e.target.checked
                    )
                  }
                  name='isMinorRegistrationAppAllowed'
                />
              }
              label="Permitir registro de menores"
            />
          </FormControl>
        </Grid>
      )}
      
      <Grid item xs={12} md={6}>
        <CorreoField
          label="Correo de notificación de recibos"
          v={entidad.notificationEmail}
          on={(e) => _handleSetDataField('notificationEmail', e.target.value)}
          e={dataRequired.notificationEmail}
        />
      </Grid>

      <Grid item md={12} align="center" sx={{ p: 2 }}>
        {tab === 1 && (
          <BotonNuevoComponent click={_handleSubmit} morado text="GUARDAR" />
        )}
      </Grid>
    </Grid>
  );

  const tabs = (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={(e, nv) => setTab(nv)} aria-label="tabs-Areas">
        <Tab label="Datos" value={1} />
        {activo ? <Tab label="Información" value={3} /> : null}
        {activo ? <Tab label="Imágenes" value={2} /> : null}
        {/* <Tab label="Parámetros" value={4} /> */}
        {activo ? <Tab label="Métodos de pago" value={5} />: null}
        {activo ? <Tab label="Morosidad" value={7} />: null}
        {activo && isRoot ? <Tab label="Ajustes (Root)" value={6} /> : null}
      </TabList>
    </Box>
  );

  return (
    <Box component="form">
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            {tabs}
            <TabPanel value={1} sx={{ p: 2 }}>
              {bodyPanel1}
            </TabPanel>
            <TabPanel value={2} sx={{ p: 2 }}>
              <FormImagenesComponents openModal={openModal} />
            </TabPanel>
            <TabPanel value={3} sx={{ p: 2 }}>
              <FormDatosEntidad openModal={openModal} />
            </TabPanel>
            {/* <TabPanel value={4} sx={{ p: 2 }}></TabPanel> */}
            <TabPanel value={5} sx={{ p: 2 }}>
              <FormMetodosPago />
            </TabPanel>
            <TabPanel value={7} sx={{ p: 2 }}>
              <FormEntityMorosity />
            </TabPanel>
            <TabPanel value={6} sx={{ p: 2 }}>
              <FormEntityRoot />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
};

FormEntidades.propTypes = {
  openModal: PropTypes.func,
};

export default FormEntidades;
