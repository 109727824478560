import {
  Box,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Rating,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { CYAN_COLOR, MORADO_COLOR } from '../../../../constants/ColorsConst';
import { SU } from '../../../../constants/ConfigConst';
import { TIPO_RESERVA_ENUM } from '../../../../constants/enumsConst';
import { dateformat } from '../../../../helpers';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { isManagerUserCode } from '../../../modulos/modules.helpers';
import ModalImage from 'react-modal-image';
import RSlider from "react-slick";
import StarIcon from '@mui/icons-material/Star';

const InfoRestaurantesComponent = ({ setOpenModalForm }) => {
  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);
  const activo = restaurantesStore.activo;
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  if (!activo) return null;

  const paymentMethodLabels = {
    TRANSFER: 'Transferencia',
    MOBILE: 'Pago móvil',
    ZELLE: 'Zelle',
    CASH_BS: 'Efectivo en Bolívares',
    CASH_US: 'Efectivo en Dólares',
    POS: 'Punto de Venta',
  }
  return (
    <Paper elevation={2}>
      <CardMedia
        sx={{
          height: 200,
          width: "100%",
          position: "relative",
        }}
        image={activo.imageUrl}
        title={activo.name}
      ></CardMedia>
      <Grid container spacing="1" sx={{ p: 2 }}>
        <Grid item md={12} align="center">
          <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
            {activo.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="center">
          <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
            Detalles
          </Divider>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Tipo de restaurante: </strong>
            {activo.type.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Precio promedio: </strong>${activo.averagePrice}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Servicios: </strong>
            {activo.services.map((s) => (
              <Chip key={s} label={s} size="small" sx={{ marginRight: 1 }} />
            ))}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Zonas: </strong>
            {activo.zones.map((z) => (
              <Chip key={z.id} label={z.name} size="small" sx={{ marginRight: 1 }} />
            ))}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Métodos de pago: </strong>
            {activo.paymentMethods.map((p) => (
              <Chip key={p} label={paymentMethodLabels[p]} size="small" sx={{ marginRight: 1 }} />
            ))}
          </Typography>
        </Grid> 
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Menu: </strong>
            {
              activo.menu ? (
              <a href={activo.menu} target="_blank" rel="noreferrer">
                {activo.menu.substring(activo.menu.lastIndexOf("/") + 1)}
              </a>
              ) : "No disponible"
            }
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Estatus: </strong>
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={activo.active ? "Activo" : "Bloqueado"}
                size="small"
                sx={{
                  backgroundColor: activo.active ? CYAN_COLOR : MORADO_COLOR,
                  color: "white",
                }}
              />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Valoración: </strong>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Rating
                name="text-feedback"
                value={activo.rating}
                readOnly
                precision={0.5}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
              <Box sx={{ ml: 2 }}>{activo.rating} ({activo.ratingCount})</Box>
            </div>
          </Typography>
        </Grid>

        {!isManager && (
          <Grid item md={12} sx={{ mt: 3 }} align="center">
            <BotonNuevoComponent
              click={() => {
                setOpenModalForm(true);
              }}
              text="EDITAR"
              morado
            />
          </Grid>
        )}
        {/* <Grid item md={6} sx={{ mt: 3 }}>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                            // setOpenModalForm(true)
                        }}
                    >
                        Bloquear
                    </Button>
                </Grid> */}
      </Grid>
    </Paper>
  );
};

export default InfoRestaurantesComponent;
