import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import {
  AZUL_COLOR,
  CYAN_COLOR,
  MORADO_COLOR,
} from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { TYPE_ENTITY_ENUM } from '../../../constants/enumsConst';
import { dateformat, getDateFormated } from '../../../helpers';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import { metodosPagoArray } from '../../entidades/components/FormMetodosPago';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteRecibosAction,
  getAllRecibosAction,
  setActivoRecibosAction,
} from '../RecibosAction';
import { recibosApi } from '../RecibosService';

export function estatusRecibos(status) {
  const array = [
    { value: 'pay', label: 'Pagado', color: CYAN_COLOR },
    { value: 'pending', label: 'Pendiente', color: AZUL_COLOR },
    { value: 'debt', label: 'Deuda', color: MORADO_COLOR },
  ];
  return (
    array.find((el) => el.value === status) || {
      value: 'debt',
      label: 'Deuda',
      color: MORADO_COLOR,
    }
  );
}

const ListadoRecibos = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const recibosStore = Redux.useSelector((state) => state.recibos);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isDetalles, setIsDetalles] = React.useState(false);
  const [reciboDetalles, setReciboDetalles] = React.useState(null);

  const timezone = entidadesStore.activo.country.timezone;

  const isPublic = entidadesStore.activo.isPublic;

  React.useEffect(() => {
    if (recibosStore.all && recibosStore.all.length === 0) {
      getAllRecibosAction(dispatch, {
        entityId: entidadesStore.activo.id,
        ...recibosStore.params,
        perMonth: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    recibosStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.DATE]: getDateFormated(el.date, 'YYYY-MM-DD', timezone),
          code: el.code?.code || null,
          userCode: el.userCode || null,
          reference: {
            reference: el.reference,
            voucher: el.voucher,
            paidDate: el.paidDate,
          },
        },
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recibosStore.all]);

  const _handleEditClick = async (id) => {
    const recibo = recibosStore.all.find((el) => el.id === id);
    await setActivoRecibosAction(dispatch, recibo);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteRecibosAction({ dispatch, addNotification }, id, {
      ...recibosStore.params,
      entityId: entidadesStore.activo.id,
    });
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: '350px' }}>
              <NameComponent
                onClick={() => _handleEditClick(tableMeta.rowData[0])}
              >
                {value}
              </NameComponent>
            </Box>
          );
        },
      },
    },
    {
      name: CONST.METHOD,
      label: 'Método pago',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ minWidth: '110px' }}>
              {metodosPagoArray.find((fi) => fi.id === value)?.name ?? ''}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.DATE,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ minWidth: '100px' }}>
              {dateformat(value, { format: 'DD-MM-YYYY' })}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.AMOUNT,
      label: 'Monto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>${value}</>;
        },
      },
    },
    {
      name: CONST.REFERENCE,
      label: 'Referencia',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value.reference ? null : (
            <Stack direction="row" alignItems="center">
              <Avatar
                onClick={() => _handleEditClick(tableMeta.rowData[0])}
                src={value.voucher}
                alt="comprobante"
                sx={{
                  width: 40,
                  height: 40,
                  mr: 1,
                }}
              />
              <Stack>
                <Typography variant="body1" color="initial">
                  <strong>Referencia: </strong> {value.reference}
                </Typography>
                <Typography variant="body1" color="initial">
                  <strong>Fecha de pago: </strong>{' '}
                  {dateformat(value.paidDate, { format: 'DD-MM-YYYY' })}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
    },
    {
      name: CONST.STATUS,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={estatusRecibos(value).label}
                size="small"
                sx={{
                  backgroundColor: estatusRecibos(value).color,
                  color: 'white',
                }}
                onClick={() => _handleEditClick(tableMeta.rowData[0])}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" style={{ width: '50px' }}>
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                  onDelete={async () => {
                    const data = recibosStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    await setReciboDetalles(data);
                    setIsDelete(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  if (!isPublic) {
    columns.splice(1, 0, {
      name: CONST.CODE,
      label: TYPE_ENTITY_ENUM[entidadesStore.entityType.name],
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    });
  } else {
    columns.splice(1, 0, {
      name: 'userCode',
      label: 'Usuario',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) return null;
          return (
            <Stack>
              <NameComponent>
                {value.user.profile.firstName} {value.user.profile.lastName}
              </NameComponent>
              <Typography variant="body1" color="initial">
                {value.user.email}
              </Typography>
            </Stack>
          );
        },
      },
    });
  }

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${recibosApi}/export`;
      const name = 'Recibos';
      const params = {
        entityId: entidadesStore.activo.id,
        ...recibosStore.params,
      };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={recibosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {reciboDetalles && (
        <Modal
          id="modalFormRecibos"
          title={`Eliminar recibo`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este recibo?
              <br />
              <br />
              <Typography variant="body1" color="initial">
                <strong>Residencia: </strong>{' '}
                {reciboDetalles.code?.code ?? '--'}
              </Typography>
              <Typography variant="body1" color="initial">
                <strong>Nombre: </strong> {reciboDetalles.name}
              </Typography>
              <Typography variant="body1" color="initial">
                <strong>Monto: </strong> {reciboDetalles.amount}
              </Typography>
              <Typography variant="body1" color="initial">
                <strong>Fecha: </strong>{' '}
                {dateformat(reciboDetalles.date, { format: 'DD-MM-YYYY' })}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(reciboDetalles.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
      {/* {reciboDetalles && (
        <Modal
          id="modalDetallesRecibos"
          title={`Detalles`}
          open={isDetalles}
          // maxWidth="xs"
          onClose={() => setIsDetalles(!isDetalles)}
        >
          <DetallesRecibosComponent
            setReciboDetalles={(value) => setReciboDetalles(value)}
            data={reciboDetalles}
          />
        </Modal>
      )} */}
    </Grid>
  );
};

export default ListadoRecibos;
