import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Paper, Tab, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import ReservasRestauranteModule from '../reservas_restaurante/ReservasRestauranteView';
import ZonasRestauranteView from '../zonas/ZonasRestauranteView';
import { getOneRestaurantesAction } from './RestaurantesAction';
import FormRestaurantes from './components/FormRestaurantes';
import InfoRestaurantesComponent from './components/InfoRestaurantes';

const PanelRestaurantesView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);

  React.useEffect(() => {
    if (
      restaurantesStore.activo === null ||
      restaurantesStore.activo === undefined
    ) {
      history.push(ROUTES.panelRestaurante.to);
      return;
    }
    if (!restaurantesStore.loadingGetAll) {
      getOneRestaurantesAction(
        dispatch,
        restaurantesStore.activo.id,
        entidadesStore.activo.id
      );
    }
  }, [restaurantesStore.all]); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const [tab, setTab] = React.useState(1);

  const tabs = [
    {
      label: 'Reservas',
      value: 1,
      component: <ReservasRestauranteModule />,
    },
    {
      label: 'Zonas',
      value: 2,
      component: <ZonasRestauranteView />,
    },
  ];

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{
                  indicator: {
                    backgroundColor: 'red',
                  },
                }}
                onChange={(e, nv) => setTab(nv)}
                aria-label="tabs-users"
              >
                <Tab label="Reservas" value={1} />
                <Tab label="Zonas" value={2} />
              </TabList>
            </Box>
            {tabs.map((tab) => (
              <TabPanel key={tab.value} value={tab.value} sx={{ p: 0 }}>
                {tab.component}
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <ContainerTemplate
      active={ROUTES.panelRestaurante}
      routes={[ROUTES.restaurantes]}
    >
      <Grid container spacing={2} sx={{ marginTop: '1px' }}>
        <Grid item md={4}>
          <InfoRestaurantesComponent
            setOpenModalForm={(val) => setOpenModalForm(val)}
          />
        </Grid>
        <Grid item md={8}>
          {tabPanel}
        </Grid>
        <Modal
          id="modalFormRestaurantes"
          title={`${restaurantesStore.activo ? 'Editar' : 'Nueva'} área`}
          open={openModalForm}
          onClose={() => setOpenModalForm(!openModalForm)}
        >
          {restaurantesStore.activo && (
            <Typography variant="h6">
              {restaurantesStore.activo.nombres}{' '}
              {restaurantesStore.activo.apellidos}
            </Typography>
          )}
          <FormRestaurantes
            activo={restaurantesStore.activo}
            setOpenModalForm={(val) => setOpenModalForm(val)}
          />
        </Modal>
      </Grid>
    </ContainerTemplate>
  );
};

export default PanelRestaurantesView;
