import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import ZonasRestauranteView from './ZonasRestauranteView';

const ZonasRestaurantePage = () => {
  return (
    <ContainerTemplate active={ROUTES.zonasRestaurante} routes={[]}>
      <ZonasRestauranteView />
    </ContainerTemplate>
  );
};

export default ZonasRestaurantePage;
