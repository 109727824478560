import { Box, Button, Chip, DialogActions, DialogContent, DialogContentText, Grid, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  getAllEntDestacadasAction,
  saveEntDestacadasAction,
  setActivoEntDestacadasAction,
} from '../EntDestacadasAction';
import { Modal } from '../../../components';
import { useNotification } from '../../../helpers/notification';

const ListadoEntDestacadas = ({ setOpenModalForm = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const [isToggleActive, setIsToggleActive] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const entDestacadasStore = Redux.useSelector((state) => state.entDestacadas);
  const { addNotification } = useNotification();
  React.useEffect(() => {
    if (entDestacadasStore.all && entDestacadasStore.all.length === 0) {
      getAllEntDestacadasAction(dispatch, entDestacadasStore.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleEditClick = async (id) => {
    const recibo = entDestacadasStore.all.find((el) => el.id === id);
    await setActivoEntDestacadasAction(dispatch, recibo);
    setOpenModalForm(true);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveEntDestacadasAction(
      { dispatch, addNotification },
      id,
      {
        active: !entDestacadasStore.activo.active,
      },
      entDestacadasStore.params
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };


  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'bannerUrl',
      label: 'Banner',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              variant="square"
              alt="logourl-entDestacada"
              src={value}
              style={{
                width: '80px',
                height: '50px',
                objectFit: 'contain',
              }}
            />
          );
        },
      },
    },
    {
      name: 'entity',
      label: 'Entidad',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value?.name}
            </NameComponent>
          );
        },
      },
    },
    {
      name: 'title',
      label: 'Título',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: 'description',
      label: 'Descripción',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: 'Dirección',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: value ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = entDestacadasStore.all.find((el) => el.id === tableMeta.rowData[0]);
          return (
            <>
              <Box align="left">
                <ActionsList
                  active={data.active}
                  id={tableMeta.rowData[0]}
                  onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                  onToggleActive={() => {
                    setActivoEntDestacadasAction(dispatch, data);
                    setIsToggleActive(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={entDestacadasStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={entDestacadasStore.all}
          columns={columns}
          options={options}
        />
      </Grid>
      {entDestacadasStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${entDestacadasStore.activo.active ? 'Bloquear' : 'Activar'} ${
            entDestacadasStore.activo.title
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                entDestacadasStore.activo?.active ? 'Bloquear' : 'Activar'
              } esta entidad destacada?`}
              <p>
                <strong>{entDestacadasStore.activo.title}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(entDestacadasStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoEntDestacadas;
