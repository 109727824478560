import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile } from '../../../components';
import LoadingComponent from '../../../components/Loading';
import * as CONST from '../../../constants/FieldsConst';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { saveEntDestacadasAction } from '../../../store/Actions';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { baseApi, token } from '../../../services/Apis';
import useFetch from 'http-react';
import BannerPreview from './BannerPreview';

const acceptOnlyImages = 'image/png, image/jpeg, image/jpg';
const FormEntDestacadas = ({ setOpenModalForm = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const [fileImage, setFileImage] = React.useState(null);
  
  const [previewUrl, setPreviewUrl] = React.useState(null)

  const entDestacadasStore = Redux.useSelector((state) => state.entDestacadas);
  const activo = entDestacadasStore.activo;

  const dataDefault = {
    title: '',
    description: '',
    address: '',
    active: true,
    entityId: null,
  };
  const [entDestacada, setRecibo] = React.useState(dataDefault);
  const [dataError, setDataRequired] = React.useState({
    title: false,
    description: false,
    address: false,
    active: false,
    entityId: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    setFileImage(null);
    setPreviewUrl(null);
    if (!activo) {
      setRecibo(dataDefault);
      return;
    }
    let data = {};
    Object.keys(entDestacada).map(
      (key) => (data[key] = entDestacadasStore.activo[key])
    );
    data.entityId = entDestacadasStore.activo.entity.id;
    setPreviewUrl(entDestacadasStore.activo.bannerUrl);
    setTimeout(() => setRecibo(data), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  React.useEffect(() => {
    if (fileImage) {
      const objectUrl = URL.createObjectURL(fileImage)
      setPreviewUrl(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [fileImage])

  const _handleSetDataField = (key, value) =>
    setRecibo({ ...entDestacada, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = entDestacada[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();
    if (valid) {
      setLoadingSave(true);
      const data = { ...entDestacada };
      if (fileImage) data.banner = fileImage;
      const res = await saveEntDestacadasAction(
        { dispatch, addNotification },
        activo?.id,
        data
      );
      if (res) {
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  
  const { data: entityData = [], loading: loadingEntities } = useFetch(
    `${baseApi}/entities/short`,
    {
      headers: token({}),
      query: {
        isPublic: true,
      },
    }
  );

  const existFeaturedEntityIds = entDestacadasStore.activo ? [] : entDestacadasStore.all.map((el) => el.entity.id);

  const entities = !entityData?.error ? entityData?.filter((entity) => !existFeaturedEntityIds.includes(entity.id)).map((entity) => ({
    label: entity.name,
    value: entity.id,
  })) : [];




  return (
    <Box component="form" sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} spacing={2} container>
            
          <Grid item xs={12} md={12}>
            <LoadingComponent
              isLoading={loadingSave}
              text={activo ? 'Actualizando...' : 'Guardando...'}
            />
          </Grid>
          <Grid item xs={12} md={12} align="center">
            <ButtonFile
              id="banner"
              onFile={(file) => setFileImage(file)}
              file={fileImage}
              text="Subir banner"
              size="40%"
              onDelete={() => setFileImage(null)}
              accept={acceptOnlyImages}
              src={
                fileImage
                  ? null
                  : entDestacadasStore.activo
                  ? entDestacadasStore.activo.bannerUrl
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id={`label-entityId`}>Entidad</InputLabel>
              <Select
                labelId={`label-entityId`}
                size="small"
                label="Entidad"
                value={entDestacada.entityId}
                onChange={(e) => _handleSetDataField('entityId', e.target.value)}
                id="entityId"
                disabled={!!entDestacadasStore.activo}
              >
                {entities.map((entity) => (
                  <MenuItem key={entity.value} value={entity.value}>
                    {entity.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
              
          <Grid item xs={12} md={12} align="center">
            <TextField
              fullWidth
              size="small"
              id="title"
              required
              label="Título"
              value={entDestacada.title}
              onChange={(e) => _handleSetDataField('title', e.target.value)}
              helperText={dataError.title ? validFielddHelper(1) : null}
              error={dataError.title}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              id="address"
              required
              label="Descripción"
              value={entDestacada.address}
              onChange={(e) => _handleSetDataField('address', e.target.value)}
              helperText={dataError.address ? validFielddHelper(1) : null}
              error={dataError.address}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              id="description"
              required
              multiline
              rows={3}
              label="Descripción"
              value={entDestacada.description}
              onChange={(e) => _handleSetDataField('description', e.target.value)}
              helperText={dataError.website ? validFielddHelper(1) : null}
              error={dataError.description}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
              <Select
                labelId={`label-${CONST.ACTIVE}`}
                size="small"
                value={entDestacada.active}
                label="Estatus"
                onChange={(e) =>
                  _handleSetDataField(CONST.ACTIVE, e.target.value)
                }
                id={CONST.ACTIVE}
              >
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Bloqueado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={0} sx={{ p: 2, border: "1px solid #e0e0e0", borderRadius: 2, height: "100%" }}>
            <Typography variant="h6" gutterBottom>
              Vista Previa del Banner
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <BannerPreview
                title={entDestacada.title}
                description={entDestacada.description}
                location={entDestacada.address}
                bannerImage={previewUrl}
                rating={4.0}
              />
            </Box>

            <Typography variant="caption" color="text.secondary" sx={{ display: "block", textAlign: "center", mt: 1 }}>
              Esta es una vista previa de cómo se verá el banner en la aplicación
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={12} sx={{ m: 2 }} align="center">
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
            mr={2}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormEntDestacadas;
