import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import useFetch from 'http-react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import {
  ButtonFile,
  GenerosField,
  TiposDocumentosField,
} from '../../../components';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import LoadingComponent from '../../../components/Loading';
import PhoneNumberField from '../../../components/fields/PhoneNumberField';
import * as CONST from '../../../constants/FieldsConst';
import { MANAGER, getLabelTypeEntity } from '../../../constants/enumsConst';
import { dateformat, getDateTimezone, validaBirthDate } from '../../../helpers';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { baseApi, token } from '../../../services/Apis';
import {
  assingCodeUsuariosAction,
  byIdentifacionUsuariosAction,
  getSearchCodesCodigosAction,
  saveUsuariosAction,
} from '../../../store/Actions';
import PasswordField from '../../auth/login/components/PasswordField';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { CorreoField, FechaNacimientoField } from './FieldsUsuarios';
import { BY_IDENTIFICACION_USUARIOS } from '../../../store/Types';

const FormUsuarios = ({ setOpenModalForm, code, isGlobal, params }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const codigosStore = Redux.useSelector((state) => state.codigos);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isPublic = entidadesStore.activo?.isPublic;

  const isMinorRegistrationAllowed =
    entidadesStore.activo?.isMinorRegistrationAllowed;

  const activo = usuariosStore.activo;

  const timezone = entidadesStore.activo?.country.timezone;

  const initState = {
    [CONST.USER_ID]: null,
    [CONST.EMAIL]: '',
    [CONST.PASSWORD]: '',
    [CONST.PASSWORD_CONFIRMATION]: '',
    [CONST.TYPE_DOCUMENT]: 'V',
    [CONST.FIRST_NAME]: '',
    [CONST.FIRST_LAST_NAME]: '',
    [CONST.BIRTHDAY]: null,
    [CONST.GENDER]: 'male',
    [CONST.PHONE]: '',
    [CONST.STATUS_ID]: 1,
    [CONST.TYPE]: 'owner',
    [CONST.CODE_ID]: code,
    [CONST.CODE]: '',
    countryId: '',
    stateId: '',
    cityId: '',
    postalCode: '',
    address: '',
    codeId: 1,
    entityUserTypeId: null,
  };

  const [fileFoto, setFileFoto] = React.useState();
  const [avatar, setAvatar] = React.useState();
  const [user, setUser] = React.useState({
    ...initState,
    [CONST.IDENTIFICATION_NUMBER]: '',
  });
  const [errorPass, setErrorPass] = React.useState(false);
  const [dataError, setDataError] = React.useState({
    [CONST.EMAIL]: false,
    [CONST.IDENTIFICATION_NUMBER]: false,
    [CONST.FIRST_NAME]: false,
    [CONST.FIRST_LAST_NAME]: false,
    [CONST.PHONE]: false,
    [CONST.CODE_ID]: false,
    [CONST.TYPE]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [dataCodes, setDataCodes] = React.useState([]);
  const [loadingDataCode, setLoadingDataCode] = React.useState(false);
  const [disabledInputsUser, setDisabledInputsUser] = React.useState(false);
  const [loadingDataUser, setLoadingDataUser] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorBirtday, setErrorBirthday] = React.useState(false);
  const [selectedCode, setSelectedCode] = React.useState(null);

  const isClub = entidadesStore.activo?.entityType?.id === 2;
  const isOffice = entidadesStore.activo?.entityType?.id === 3;

  const { data: entityUserTypesData, loading: loadingEntityUserTypes } =
    useFetch(`${baseApi}/entity-user-types/short`, {
      id: 'entity-user-types',
      headers: token({}),
      query: {
        entityId: entidadesStore.activo?.id,
      },
    });

  const entityUserTypes = entityUserTypesData
    ? entityUserTypesData
        ?.filter((item) => {
          return item.codeType === selectedCode?.codeType;
        })
        .map((item) => ({
          id: item.id,
          name: item.name,
        }))
    : [];

  React.useEffect(() => {
    const fetchData = async () => {
      if (entidadesStore.activo) {
        await getSearchCodesCodigosAction(
          dispatch,
          entidadesStore.activo.id,
          ''
        );
      }

      // if (countriesStore.all.length === 0) {
      //   // await getAllCountriesAction(dispatch);
      // }
      // setUser({
      //   ...user,
      //   countryId: activo.profile?.country?.id,
      //   stateId: activo.profile?.state?.id,
      //   cityId: activo.profile?.city?.id,
      // });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: countriesData, loading: loadingCountries } = useFetch(
    `${baseApi}/countries`,
    {
      headers: token({}),
    }
  );

  const { data: statesData, loading: loadingStates } = useFetch(
    `${baseApi}/states`,
    {
      headers: token({}),
      query: {
        countryId: user.countryId,
      },
    }
  );

  const { data: citiesData, loading: loadingCities } = useFetch(
    `${baseApi}/cities`,
    {
      headers: token({}),
      query: {
        stateId: user.stateId,
      },
    }
  );

  const countries = countriesData?.data || [];
  const states = statesData?.data || [];
  const cities = citiesData?.data || [];

  React.useEffect(() => {
    const fetchData = () => {
      if (activo === null) return;

      const userCode = activo.userCodes.length > 0 ? activo.userCodes[0] : null;

      let d = {
        [CONST.USER_ID]: activo.id,
        [CONST.EMAIL]: activo.email,
        [CONST.PASSWORD]: '',
        [CONST.PASSWORD_CONFIRMATION]: '',
        [CONST.TYPE_DOCUMENT]: activo.profile?.typeDocument,
        [CONST.IDENTIFICATION_NUMBER]: activo.profile?.identificationNumber,
        [CONST.FIRST_NAME]: activo.profile?.firstName,
        [CONST.FIRST_LAST_NAME]: activo.profile?.lastName,
        [CONST.BIRTHDAY]: activo.profile?.birthday,
        [CONST.GENDER]: activo.profile?.gender,
        [CONST.PHONE]: activo.profile?.phone,
        countryId: activo.profile?.country?.id,
        stateId: activo.profile?.state?.id,
        cityId: activo.profile?.city?.id,
        postalCode: activo.profile?.postalCode,
        address: activo.profile?.address,
        entityUserTypeId: null,
      };

      if (userCode) {
        d[CONST.TYPE] = userCode.userType;
        d[CONST.CODE_ID] = userCode.code.id.toString();
        d[CONST.CODE] = userCode.code.code;
      }

      setAvatar(activo.profile?.avatar);
      setTimeout(() => {
        setUser(d);
      }, 300);
    };
    fetchData();
  }, [activo]);

  React.useEffect(() => {
    const fetchData = () => {
      if (activo) return;
      return _handleSetDataField(CONST.PASSWORD, user.identificationNumber);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.identificationNumber]);

  React.useEffect(() => {
    const fetchData = () => {
      return _handleSetDataField(CONST.PASSWORD_CONFIRMATION, user.password);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.password]);

  React.useEffect(() => {
    const fetchData = () => {
      setDataCodes([]);
      if (codigosStore.searchCodes.length > 0) {
        codigosStore.searchCodes.forEach((el) => {
          setDataCodes((old) => [
            ...old,
            {
              [CONST.ID]: el.id,
              [CONST.CODE]: el.code,
            },
          ]);
        });
      }
    };
    fetchData();
  }, [codigosStore.searchCodes]);

  React.useEffect(() => {
    const fetchData = () => {
      if (usuariosStore.activo) return;

      let timer;
      if (user.identificationNumber) {
        setLoadingDataUser(true);
        timer = setTimeout(async () => {
          await byIdentifacionUsuariosAction(
            { dispatch, addNotification },
            {
              number: user.identificationNumber,
              entityId: entidadesStore.activo.id,
            }
          );
          setLoadingDataUser(false);
        }, 1500);
      }
      if (timer) {
        return () => clearTimeout(timer);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.identificationNumber]);

  React.useEffect(() => {
    const fetchData = () => {
      if (usuariosStore.activo) return;

      if (usuariosStore.userByIdentificacion) {
        setDisabledInputsUser(true);
        const u = usuariosStore.userByIdentificacion;
        const p = u.profile;
        setAvatar(p.avatar);
        let d = {
          ...initState,
          [CONST.EMAIL]: u.email,
          [CONST.TYPE_DOCUMENT]: p.typeDocument.toString(),
          [CONST.IDENTIFICATION_NUMBER]: p.identificationNumber,
          [CONST.FIRST_NAME]: p.firstName,
          [CONST.FIRST_LAST_NAME]: p.lastName,
          [CONST.BIRTHDAY]: p.birthday,
          [CONST.GENDER]: p.gender,
          [CONST.PHONE]: p.phone,
        };
        setUser(d);
      } else {
        setUser(initState);
        setDisabledInputsUser(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuariosStore.userByIdentificacion]);

  React.useEffect(() => {
    const fetchData = () => {
      setErrorBirthday(
        !validaBirthDate(user.birthday, 12, isMinorRegistrationAllowed)
      );
    };
    fetchData();
  }, [user.birthday]);

  React.useEffect(() => {
    return () => {
      dispatch({
        type: BY_IDENTIFICACION_USUARIOS,
        payload: null,
      });
    };
  }, []);

  const _handleSetDataField = (key, value) =>
    setUser({ ...user, [key]: value });

  const _validDataForm = () => {
    const errorP =
      activo === null &&
      (user.password === '' ||
        user.password === null ||
        user.password === undefined);
    setErrorPass(errorP);
    setErrorEmail(false);

    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = user[key];
      const valid =
        val === null || val === undefined || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    if (usuariosStore.userByIdentificacion) {
      if (user.codeId === undefined || user.codeId === null) {
        addNotification('Falta asignar un código', { warning: true });
        return;
      }

      setLoadingSave(true);
      const data = {
        entityUserTypeId: user.entityUserTypeId,
        avatar: fileFoto,
      };
      const res = await assingCodeUsuariosAction(
        { dispatch, addNotification },
        usuariosStore.userByIdentificacion.id,
        user.codeId,
        data,
        { ...params, page: 1 }
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
      return;
    }

    const valid = await _validDataForm();
    if (valid) {
      if (user.identificationNumber.length < 6) {
        setDataError(CONST.PASSWORD, true);
      }
      const data = {
        [CONST.AVATAR]: fileFoto,
        [CONST.TYPE_DOCUMENT]: user.typeDocument,
        [CONST.IDENTIFICATION_NUMBER]: user.identificationNumber,
        [CONST.FIRST_NAME]: user.firstName,
        [CONST.FIRST_LAST_NAME]: user.lastName,
        [CONST.BIRTHDAY]: getDateTimezone(
          moment(user.birthday).startOf('day'),
          timezone
        ),
        [CONST.PHONE]: user.phone,
        [CONST.GENDER]: user.gender,
        [CONST.TYPE]: user.type,
        [CONST.CODE_ID]: user.codeId,
        [CONST.EMAIL]: user.email,
        countryId: user.countryId,
        stateId: user.stateId,
        cityId: user.cityId,
        postalCode: user.postalCode,
        address: user.address,
        entityUserTypeId: user.entityUserTypeId,
      };

      console.log('data para el server', data);

      if (user.password) data[CONST.PASSWORD] = user.password;

      setLoadingSave(true);
      let res;

      const userParams = {};
      if (!isGlobal) {
        userParams.entityId = entidadesStore.activo.id;
      }

      res = await saveUsuariosAction(
        { dispatch, addNotification },
        activo ? activo.id : null,
        data,
        userParams
      );

      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  const selectCode = (
    <Autocomplete
      freeSolo
      id={`idpassow`}
      disableClearable
      options={dataCodes.map((option) => option.code)}
      size="small"
      loading={loadingDataCode}
      required
      autoComplete="off"
      sx={{
        border: dataError[CONST.CODE_ID] ? '1px solid red' : null,
        borderRadius: '4px',
      }}
      value={user.code}
      onChange={(event, newValue) => {
        const item = codigosStore.searchCodes.find((a) => a.code === newValue);
        console.log(item);
        setSelectedCode(item);
        setUser({
          ...user,
          [CONST.TYPE]: item?.codeType === 'admin' ? MANAGER : item?.codeType,
          [CONST.CODE]: item?.code,
          [CONST.CODE_ID]: item?.id,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => doSearch(e.target.value)}
          label={`Buscar y asignar ${getLabelTypeEntity(
            entidadesStore.entityType.name
          )}`}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <React.Fragment>
                {loadingDataCode ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

  var ExpRegSoloNumeros = '^[0-9]+$';
  var ExpRegSoloNumerosPassport = '^[0-9a-zA-Z]+$';
  var ExpRegSoloLetras = '^[a-z A-ZñÑáéíóúÁÉÍÓÚ]+$';
  var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

  const inputsForm = (
    <>
      <Grid item xs={12} md={6}>
        <TiposDocumentosField
          value={user.typeDocument}
          onChange={(e, v) =>
            _handleSetDataField(CONST.TYPE_DOCUMENT, e.target.value)
          }
          error={dataError.typeDocument}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.IDENTIFICATION_NUMBER}
          required
          label="Número de identificación"
          value={user.identificationNumber}
          onChange={(e) => {
            let text = e.target.value;

            if (user.typeDocument.toString() === 'V') {
              text = text.replace(/[^0-9]/g, '');

              if (text.length === 9) return;
            }

            let v = false;
            if (user.typeDocument.toString() === 'P') {
              if (text.length === 20) return;
              v = text.match(ExpRegSoloNumerosPassport) != null;
            } else {
              v = text.match(ExpRegSoloNumeros) != null;
            }

            if (text === '') {
              _handleSetDataField(CONST.IDENTIFICATION_NUMBER, '');
              return;
            }

            if (!v) {
              text = text.slice(0, -1);
            }

            _handleSetDataField(CONST.IDENTIFICATION_NUMBER, text);
          }}
          helperText={
            dataError.identificationNumber ? validFielddHelper(1) : null
          }
          error={dataError.identificationNumber}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.FIRST_NAME}
          required
          label="Nombre"
          value={user.firstName}
          disabled={disabledInputsUser}
          onChange={(e) => {
            let text = e.target.value;
            const v = text.match(ExpRegSoloLetras) != null;

            if (text === '') {
              _handleSetDataField(CONST.FIRST_NAME, '');
              return;
            }
            if (!v) {
              text = text.slice(0, -1);
              return;
            }

            _handleSetDataField(CONST.FIRST_NAME, text);
          }}
          helperText={dataError.firstName ? validFielddHelper(1) : null}
          error={dataError.firstName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.FIRST_LAST_NAME}
          required
          label="Apellido"
          value={user.lastName}
          disabled={disabledInputsUser}
          onChange={(e) => {
            let text = e.target.value;
            const v = text.match(ExpRegSoloLetras) != null;
            if (text === '') {
              _handleSetDataField(CONST.FIRST_LAST_NAME, '');
              return;
            }

            if (!v) {
              text = text.slice(0, -1);
              return;
            }

            _handleSetDataField(CONST.FIRST_LAST_NAME, text);
          }}
          helperText={dataError.lastName ? validFielddHelper(1) : null}
          error={dataError.lastName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GenerosField
          value={user.gender}
          onChange={(e, v) => _handleSetDataField(CONST.GENDER, e.target.value)}
          error={dataError.gender}
          disabled={disabledInputsUser}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FechaNacimientoField
          v={user.birthday}
          on={(e) => _handleSetDataField(CONST.BIRTHDAY, dateformat(e, {}))}
          e={errorBirtday}
          ht={errorBirtday ? 'Este campo esta incorrecto' : null}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneNumberField
          value={user.phone}
          onChange={(value) => _handleSetDataField(CONST.PHONE, value)}
          disabled={disabledInputsUser}
          error={dataError.phone}
          fieldName={CONST.PHONE}
          required={true}
          dataError={dataError}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CorreoField
          v={user.email}
          on={(e) => {
            let text = e.target.value;
            _handleSetDataField(CONST.EMAIL, text);
            if (validEmail.test(text)) {
              setDataError({
                ...dataError,
                [CONST.EMAIL]: false,
              });
              setErrorEmail(false);
              return;
            }
            setErrorEmail(true);
            setDataError({
              ...dataError,
              [CONST.EMAIL]: true,
            });
          }}
          dis={!!usuariosStore.activo || disabledInputsUser}
          autoComplete="off"
          errorEmail={errorEmail}
          e={errorEmail || dataError.email}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PasswordField
          size="small"
          showPassword
          value={user.password}
          onChange={(e) => {
            _handleSetDataField(CONST.PASSWORD, e.target.value);
          }}
          mtl="-6px"
          req={activo === null}
          error={errorPass}
          disabled={disabledInputsUser}
          id="idpassword"
          autoComplete="off"
          colorLabel={disabledInputsUser ? '#bababa' : null}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <EstatusField
          value={user.status_id}
          onChange={(e) =>
            _handleSetDataField(CONST.STATUS_ID, e.target.value)
          }
          error={dataError.status_id}
          disabled={disabledInputsUser || activo === null}
        />
      </Grid> */}
    </>
  );
  // const isCodeClient =
  //   selectedCode?.codeType !== SECURITY &&
  //   selectedCode?.codeType !== ADMIN &&
  //   selectedCode?.codeType !== TEACHER;

  // const _lx = {
  // 'owner': ['']
  // }

  const inputCode = (
    <>
      {code !== null && code !== undefined ? null : (
        <Grid item xs={12} md={6}>
          {selectCode}
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="selectUserType">Tipo de perfil</InputLabel>
          <Select
            labelId="selectUserType"
            size="small"
            value={user.entityUserTypeId}
            label="Tipo de perfil"
            onChange={(e) =>
              _handleSetDataField('entityUserTypeId', e.target.value)
            }
            id={CONST.TYPE}
          >
            {entityUserTypes.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="selectUserType">Tipssso de perfil</InputLabel>
          <Select
            labelId="selectUserType"
            size="small"
            value={user.type}
            label="Tipo de perfil"
            onChange={(e) => _handleSetDataField(CONST.TYPE, e.target.value)}
            id={CONST.TYPE}
          >
            {loginStore.rol === SU && selectedCode?.codeType === ADMIN && (
              <MenuItem value={ADMIN}>Administrador</MenuItem>
            )}

            {selectedCode?.codeType === STAFF && (
              <MenuItem value={STAFF}>Staff</MenuItem>
            )}

            {(loginStore.rol === ADMIN || loginStore.rol === SU) &&
              selectedCode?.codeType === ADMIN && (
                <MenuItem value={MANAGER}>Manager</MenuItem>
              )}

            {!isPublic && isCodeClient && !isOffice && (
              <MenuItem value={OWNER}>{getCodeType(OWNER, isClub)}</MenuItem>
            )}
            {!isPublic && isCodeClient && !isOffice && (
              <MenuItem value={FAMILY}>{getCodeType(FAMILY, isClub)}</MenuItem>
            )}
            {!isPublic && isCodeClient && !isOffice && (
              <MenuItem value={SERVICE}>Trabajador doméstico</MenuItem>
            )}
            {!isPublic && isCodeClient && !isOffice && (
              <MenuItem value={NANNY}>Niñera</MenuItem>
            )}

            {!isPublic && isCodeClient && !isOffice && (
              <MenuItem value={DRIVER}>Chofer</MenuItem>
            )}
            {!isPublic && isCodeClient && !isClub && !isOffice && (
              <MenuItem value={OTHER}>{getCodeType(OTHER, isClub)}</MenuItem>
            )}
            {isPublic && isCodeClient && !isOffice && (
              <MenuItem value={CLIENT}>Cliente</MenuItem>
            )}
            {selectedCode?.codeType === SECURITY && (
              <MenuItem value={SECURITY}>Seguridad</MenuItem>
            )}
            {selectedCode?.codeType === TEACHER && (
              <MenuItem value={TEACHER}>Profesor</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid> */}
    </>
  );
  const form = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" color="initial">
                Información
              </Typography>
            </Grid>
            {inputsForm}
            {!usuariosStore.activo && inputCode}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" color="initial">
                Dirección
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {loadingCountries ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{
                    borderRadius: 2,
                  }}
                />
              ) : (
                <FormControl size="small" sx={{ width: '100%' }}>
                  <InputLabel id="select-country">País</InputLabel>
                  <Select
                    labelId="select-country"
                    size="small"
                    value={user.countryId}
                    label="País"
                    onChange={async (e) => {
                      setUser({
                        ...user,
                        countryId: e.target.value,
                        stateId: '',
                        cityId: '',
                      });
                    }}
                    id="select-country"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              {loadingStates ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{
                    borderRadius: 2,
                  }}
                />
              ) : (
                <FormControl size="small" sx={{ width: '100%' }}>
                  <InputLabel id="select-states">Estado</InputLabel>
                  <Select
                    labelId="select-states"
                    size="small"
                    value={user.stateId}
                    label="Estado"
                    onChange={async (e) => {
                      setUser({
                        ...user,
                        stateId: e.target.value,
                        cityId: '',
                      });
                    }}
                    id="select-states"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              {loadingCities ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{
                    borderRadius: 2,
                  }}
                />
              ) : (
                <FormControl size="small" sx={{ width: '100%' }}>
                  <InputLabel id="select-cities">Ciudad</InputLabel>
                  <Select
                    labelId="select-cities"
                    size="small"
                    value={user.cityId}
                    label="Ciudad"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        cityId: e.target.value,
                      });
                    }}
                    id="select-cities"
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id="code-postal"
                label="Código postal"
                value={user.postalCode}
                onChange={(e) =>
                  _handleSetDataField('postalCode', e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id="field-address"
                label="Dirección"
                multiline
                rows={3}
                value={user.address}
                onChange={(e) => _handleSetDataField('address', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box component="form" sx={{ height: '450px' }}>
      <LoadingComponent
        isLoading={loadingSave || loadingDataUser}
        text={
          loadingDataUser
            ? 'Consultando número de identificación...'
            : activo
            ? 'Actualizando...'
            : 'Guardando...'
        }
      />
      <Grid container>
        <Grid item xs={12} md={2}>
          <ButtonFile
            id={CONST.IMG_FOTO}
            onFile={(file) => setFileFoto(file)}
            onDelete={() => setFileFoto(null)}
            text="Subir Foto"
            sxc={{ marginTop: '5px' }}
            name={'Mi foto'}
            file={fileFoto}
            size={130}
            src={fileFoto ? null : avatar}
            accept={acceptOnlyImages}
          />
        </Grid>
        <Grid item xs={12} md={10} sx={{ mt: 1 }}>
          {form}
        </Grid>
        <Grid item md={12} sx={{ mt: 3 }} align="center">
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

FormUsuarios.propTypes = {
  setOpenModalForm: PropTypes.func,
};

export default FormUsuarios;
