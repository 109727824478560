import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import * as React from 'react';
import ModalImage from 'react-modal-image';
import * as Redux from 'react-redux';
import { ButtonFile, Modal } from '../../../components';
import AlertMsgComponent from '../../../components/AlertMsg';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import LoadingComponent from '../../../components/Loading';
import { MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import {
  TYPE_ENTITY_ENUM,
  getLabelTypeEntity,
} from '../../../constants/enumsConst';
import { dateformat, getDateTimezone } from '../../../helpers';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { reciboPng } from '../../../static/images';
import {
  getSearchCodesCodigosAction,
  getSearchUserCodesAction,
  restartPaidRecibosAction,
  saveRecibosAction,
  setActivoRecibosAction,
} from '../../../store/Actions';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { metodosPagoArray } from '../../entidades/components/FormMetodosPago';
import { FechaField } from '../../usuarios/components/FieldsUsuarios';
import { estatusRecibos } from './ListadoRecibos';
import useFetch from 'http-react';
import { baseApi, token } from '../../../services/Apis';

const arrayStatusRecibo = [
  {
    id: 'debt',
    name: 'Deuda',
  },
  {
    id: 'pay',
    name: 'Pagado',
  },
  {
    id: 'pending',
    name: 'Pendiente',
  },
];

const FormRecibos = ({ setOpenModalForm = () => {}, setIsPayView }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const recibosStore = Redux.useSelector((state) => state.recibos);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const loginStore = Redux.useSelector((state) => state.login);

  const activeEntidad = entidadesStore.activo;
  const activo = recibosStore.activo;
  const timezone = entidadesStore.activo.country.timezone;
  const isPublic = entidadesStore.activo.isPublic;

  const dataDefault = {
    [CONST.NAME]: '',
    [CONST.DATE]: dateformat(null, {
      full: true,
    }),
    [CONST.AMOUNT]: 1,
    [CONST.ACCOUNT]: 1,
    [CONST.STATUS]: 'debt',
    paymentMethodId: '',
    [CONST.REFERENCE]: '',
  };
  const [recibo, setRecibo] = React.useState(dataDefault);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.DATE]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [listaCodes, setListaCodes] = React.useState([]);
  const [listaUsers, setListaUsers] = React.useState([]);
  const [dataCodes, setDataCodes] = React.useState([]);
  const [dataUsers, setDataUsers] = React.useState([]);
  const [codesIdsError, setCodesTdsError] = React.useState();
  const [usersIdsError, setUsersTdsError] = React.useState();
  const [loadingDataCode, setLoadingDataCode] = React.useState(false);
  const [loadingDataUser, setLoadingDataUser] = React.useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = React.useState('');
  const [isRestartPaid, setIsRestartPaid] = React.useState(false);
  const [isEditForm, setIsEdithForm] = React.useState(false);
  const [isPay, setIsPay] = React.useState(false);

  const [fileVoucher, setFileVoucher] = React.useState();

  React.useEffect(() => {
    if (activo === null) {
      setRecibo(dataDefault);
      setListaCodes([]);
      setListaUsers([]);
      return;
    }
    let data = {};
    Object.keys(recibo).map((key) => (data[key] = recibosStore.activo[key]));
    setTimeout(() => {
      setRecibo(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setRecibo({ ...recibo, [key]: value });

  const handleRestartPaid = async () => {
    const userCode = loginStore.user?.userCodes.find(
      (el) => el.code.entity?.id === activeEntidad.id
    );
    const data = {};
    if (userCode) {
      data.userCodeId = userCode.id;
    }
    let res = await restartPaidRecibosAction(
      { dispatch, addNotification },
      activo.id,
      data,
      recibosStore.params
    );
    setIsRestartPaid(false);
  };

  React.useEffect(() => {
    setDataCodes([]);
    if (codigosStore.searchCodes.length > 0) {
      codigosStore.searchCodes.forEach((code) => {
        setDataCodes((old) => [
          ...old,
          {
            [CONST.ID]: code.id,
            [CONST.CODE]: code.code,
          },
        ]);
      });
    }
  }, [codigosStore.searchCodes]);

  React.useEffect(() => {
    setDataUsers([]);
    if (
      usuariosStore.searchUserCodes &&
      usuariosStore.searchUserCodes.length > 0
    ) {
      usuariosStore.searchUserCodes.map((userCode) => {
        console.log(userCode);
        return setDataUsers((old) => [
          ...old,
          {
            [CONST.ID]: userCode.id,
            [CONST.NAME]: `${userCode.user.profile.firstName} ${userCode.user.profile.lastName}`,
            email: userCode.user.email,
          },
        ]);
      });
    }
  }, [usuariosStore.searchUserCodes]);

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = recibo[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();
    setCodesTdsError(null);
    if (!isPublic) {
      if (!activo && listaCodes.length === 0) {
        setCodesTdsError({
          severity: 'error',
          title: 'Falta seleccionar al menos un código',
        });
        return;
      }
    } else {
      if (!activo && listaUsers.length === 0) {
        setUsersTdsError({
          severity: 'error',
          title: 'Falta seleccionar al menos un usuario',
        });
        return;
      }
    }

    if (valid) {
      const codeIds = listaCodes.map((e) => e.id);
      const userCodeIds = listaUsers.map((e) => e.id);

      const data = {
        ...recibo,
        amount: Number(recibo.amount) || 0,
        account: Number(recibo.account),
        date: getDateTimezone(moment(recibo.date).startOf('day'), timezone),
        voucher: fileVoucher,
      };

      if (!isPublic) data.codeIds = codeIds;
      else data.userCodeIds = userCodeIds;

      setLoadingSave(true);
      let res = await saveRecibosAction(
        { dispatch, addNotification },
        activo?.id,
        data,
        {
          ...recibosStore.params,
          entityId: entidadesStore.activo.id,
          perMonth: true,
        }
      );
      setIsEdithForm(false);
      if (!activo && res) {
        setRecibo(dataDefault);
        setListaCodes([]);
        setListaUsers([]);
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  var delayTimerUser;
  function doSearchUser(text) {
    clearTimeout(delayTimerUser);
    setLoadingDataUser(true);
    delayTimerUser = setTimeout(async () => {
      await getSearchUserCodesAction(dispatch, entidadesStore.activo.id, text);
      setLoadingDataUser(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  const [isExiste, setIsExiste] = React.useState(false);
  const [isExisteData, setIsExisteData] = React.useState(null);
  let selectCode;

  // const { data: paymentMethods = [] } = useFetch({
  //   url: `${baseApi}/payment-methods/short`,
  //   headers: token({}),
  //   query: { byEntity: true },
  // });

  const referencePaymentMethods = entidadesStore.activo.paymentMethods.filter(pm => !['TRANSFER', 'MOBILE', 'ZELLE'].includes(pm.code));

  if (!isPublic) {
    selectCode = activo ? null : (
      <Autocomplete
        freeSolo
        id={`idpassow`}
        disableClearable
        options={dataCodes.map((option) => option.code)}
        size="small"
        loading={loadingDataCode}
        required
        autoComplete="off"
        value={autoCompleteValue}
        onChange={(event, newValue) => {
          setAutoCompleteValue('');
          const item = codigosStore.searchCodes.find(
            (a) => a.code === newValue
          );
          const existe = listaCodes.find((e) => {
            if (e) {
              return e.code === newValue;
            }
            return false;
          });
          if (existe) {
            setIsExisteData(item);
            setIsExiste(true);
            setTimeout(() => {
              setIsExiste(false);
              setIsExisteData(null);
            }, 2000);
            return;
          }
          if (item) {
            setListaCodes((old) => [...old, item]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => doSearch(e.target.value)}
            label={`Buscar y asignar ${getLabelTypeEntity(
              entidadesStore.entityType.name
            )}`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingDataCode ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    selectCode = activo ? null : (
      <Autocomplete
        freeSolo
        id={'selectCode'}
        disableClearable
        options={dataUsers.map((option) => option.name)}
        size="small"
        loading={loadingDataUser}
        value={autoCompleteValue}
        onChange={(event, newValue) => {
          setAutoCompleteValue('');
          const item = usuariosStore.searchUserCodes.find(
            (a) =>
              `${a.user.profile.firstName} ${a.user.profile.lastName}` ===
              newValue
          );
          const existe = listaUsers.find((e) => {
            if (e) {
              return e.id === item.id;
            }
            return false;
          });
          if (existe) {
            setIsExisteData(item);
            setIsExiste(true);
            setTimeout(() => {
              setIsExiste(false);
              setIsExisteData(null);
            }, 2000);
            return;
          }
          if (item) {
            setListaUsers((old) => [...old, item]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => {
              if (e) {
                doSearchUser(e.target.value);
              }
            }}
            label={`Buscar por usuario`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingDataUser && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  const formComprobante = (
    <Grid container spacing={isEditForm ? 2 : 1}>
      <Grid item xs={12} md={12}>
        {isEditForm || isPay || !activo?.paymentMethod ? (
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="selectTipoPago">Métodos de pago</InputLabel>
            <Select
              labelId="selectTipoPago"
              size="small"
              label="Método de pago"
              value={recibo.paymentMethodId}
              onChange={(e) =>
                _handleSetDataField('paymentMethodId', e.target.value)
              }
              id={CONST.METHOD}
            >
              {entidadesStore.activo.paymentMethods.map((pm) => (
                <MenuItem key={pm.id} value={pm.id}>
                  {pm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body1" color="initial">
            <strong>Método de pago: </strong>
            {activo 
              ? activo.paymentMethod?.name
              : ''}
          </Typography>
        )}
      </Grid>
      {!referencePaymentMethods.map(pm => pm.id).includes(recibo.paymentMethodId) && (
        <Grid item xs={12} md={12}>
          {isEditForm || isPay || !activo?.reference ? (
            <TextField
              fullWidth
              size="small"
              id={CONST.REFERENCE}
              required={!isPay}
              label="Número de referencia"
              value={recibo.reference}
              onChange={(e) =>
                _handleSetDataField(CONST.REFERENCE, e.target.value)
              }
              helperText={dataError.reference ? validFielddHelper(1) : null}
              error={dataError.reference}
            />
          ) : (
            <Typography variant="body1" color="initial">
              <strong>Número de Referencia: </strong> {activo?.reference}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {isEditForm || isPay || !activo?.voucher ? (
          <ButtonFile
            id={CONST.IMG_FOTO}
            onFile={(filex) => setFileVoucher(filex)}
            onDelete={() => setFileVoucher(null)}
            text="Subir comprobante de pago"
            sxc={{ marginTop: '5px' }}
            file={fileVoucher}
            size="100%"
            accept={acceptOnlyImages}
            src={fileVoucher ? null : activo?.voucher}
          />
        ) : (
          <>
            <Typography variant="body1" color="initial">
              <strong>Comprobante de pago </strong>
            </Typography>
            <ModalImage
              small={activo?.voucher ?? reciboPng}
              large={activo?.voucher ?? reciboPng}
              alt="comprobante"
              className="img-100"
            />
          </>
        )}
      </Grid>
    </Grid>
  );
  const infoRecibo = (
    <>
      <Typography variant="body1" color="initial" mb={1}>
        <strong>Nombre: </strong> {activo?.name}
      </Typography>
      <Typography variant="body1" color="initial" mb={1}>
        <strong>Fecha: </strong>{' '}
        {dateformat(activo?.date, { format: 'DD-MM-YYYY' })}
      </Typography>
      <Typography variant="body1" color="initial" mb={1}>
        <strong>Monto: </strong> ${activo?.amount}
      </Typography>
      <Typography variant="body1" color="initial" mb={1}>
        <strong>{getLabelTypeEntity(entidadesStore.entityType.name)}: </strong>{' '}
        {activo?.code?.code || '--'}
      </Typography>
      {activo?.userCode?.user && (
        <Typography variant="body1" color="initial" mb={1}>
          <strong>Usuario: </strong>{' '}
          {`${activo?.userCode.user.profile.firstName} ${activo?.userCode.user.profile.lastName}`}
        </Typography>
      )}
      <Typography variant="body1" color="initial">
        <strong>Estatus: </strong> {estatusRecibos(activo?.status).label}
      </Typography>
    </>
  );

  const formRecibo = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.NAME}
          required
          label="Nombre"
          value={recibo.name}
          onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
          helperText={dataError.name ? validFielddHelper(1) : null}
          error={dataError.name}
        />
      </Grid>
      <Grid item xs={12} md={isPay ? 6 : 12}>
        <FechaField
          l="Fecha"
          v={recibo.date}
          on={(e) =>
            _handleSetDataField(CONST.DATE, dateformat(e, { full: true }))
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          id={CONST.AMOUNT}
          required
          label="Monto"
          value={recibo.amount}
          type="number"
          inputProps={{ min: 1, max: 1000 }}
          min={1}
          onChange={(e) =>
            _handleSetDataField(CONST.AMOUNT, parseFloat(e.target.value))
          }
          helperText={dataError.amount ? validFielddHelper(1) : null}
          error={dataError.amount}
        />
      </Grid>
      <Grid item xs={12} md={isPay ? 12 : 6}>
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="selectTipoPago">Estatus</InputLabel>
          <Select
            labelId="selectTipoPago"
            size="small"
            label="Estatus"
            value={recibo.status}
            onChange={(e) => {
              _handleSetDataField(CONST.STATUS, e.target.value);
              const isPayData = ['pay', 'pending'].includes(e.target.value);
              setIsPay(isPayData);
              setIsPayView(isPayData);
            }}
            id={CONST.STATUS}
          >
            {arrayStatusRecibo.map((el) => [
              <MenuItem key={el.name} value={el.id}>
                {el.name}
              </MenuItem>,
            ])}
          </Select>
        </FormControl>
      </Grid>

      {!isPublic && !activo && (
        <>
          <Grid item xs={12} md={12}>
            <AlertMsgComponent alert={codesIdsError} />
            {selectCode}
            {isExiste && (
              <FormHelperText error={true} id="component-error-text-code">
                El código se encuentra ya seleccionado.
              </FormHelperText>
            )}
          </Grid>
          {listaCodes.length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {TYPE_ENTITY_ENUM[entidadesStore.entityType.name]} seleccionados
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <div
              direction="row"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {codigosStore.searchCodes.length > 0
                ? listaCodes.map((e) => {
                    if (e) {
                      return (
                        <Chip
                          label={e.code}
                          onClick={() => {}}
                          sx={{ mr: 1, mb: 1 }}
                          color={
                            isExisteData && isExisteData.id === e.id
                              ? 'error'
                              : 'default'
                          }
                          onDelete={() => {
                            setListaCodes(
                              listaCodes.filter((o) => o.id !== e.id)
                            );
                          }}
                        />
                      );
                    }
                  })
                : null}
            </div>
          </Grid>
        </>
      )}

      {isPublic && (
        <>
          <Grid item xs={12} md={12}>
            <AlertMsgComponent alert={usersIdsError} />
            {selectCode}
            {isExiste && (
              <FormHelperText error={true} id="component-error-text-code">
                EL usuario se encuentra ya seleccionado.
              </FormHelperText>
            )}
          </Grid>
          {listaUsers.length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Usuarios seleccionados
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <div
              direction="row"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {usuariosStore?.searchUserCodes?.length > 0
                ? listaUsers.map((e) => {
                    return (
                      <Chip
                        label={e.user.email}
                        onClick={() => {}}
                        sx={{ mr: 1, mb: 1 }}
                        color={
                          isExisteData && isExisteData.id === e.id
                            ? 'error'
                            : 'default'
                        }
                        onDelete={() => {
                          setListaUsers(
                            listaUsers.filter((o) => o.id !== e.id)
                          );
                        }}
                      />
                    );
                  })
                : null}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Box component="form">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={activo || isPay ? 6 : 12}>
              {activo && (
                <Grid item xs={12} md={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                      Información de recibo
                    </Typography>
                    <IconButton
                      aria-label="Editar"
                      onClick={() => {
                        setIsEdithForm(!isEditForm);
                      }}
                    >
                      {isEditForm ? <EditOffIcon /> : <EditIcon />}
                    </IconButton>
                  </Stack>
                </Grid>
              )}
              {isEditForm || !activo ? formRecibo : infoRecibo}
            </Grid>
            {(activo || isPay) && (
              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {activo?.status !== 'debt'
                      ? 'Información'
                      : 'Subir información'}{' '}
                    de pago
                  </Typography>
                  {activo?.reference && (
                    <IconButton
                      aria-label="Eliminar pago"
                      onClick={() => {
                        setIsRestartPaid(true);
                      }}
                      sx={{ mr: 2 }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  )}
                </Stack>
                {(activo || isPay) && formComprobante}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <BotonNuevoComponent
              click={_handleSubmit}
              text={activo ? 'ACTUALIZAR' : 'GUARDAR'}
              disabled={loadingSave}
              morado
              mr={2}
            />
            {activo?.status === 'pending' && (
              <BotonNuevoComponent
                click={async () => {
                  setLoadingSave(true);
                  const res = await saveRecibosAction(
                    { dispatch, addNotification },
                    activo.id,
                    {
                      status: 'pay',
                      date: activo.date,
                    },
                    {
                      ...recibosStore.params,
                      entityId: entidadesStore.activo.id,
                      perMonth: true,
                    }
                  );
                  setLoadingSave(false);
                }}
                text="COMPROBAR PAGO"
                green
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {isRestartPaid && (
        <Modal
          id="modalFormRecibos"
          title={`Reiniciar información de pago`}
          open={isRestartPaid}
          maxWidth="sm"
          onClose={() => setIsRestartPaid(false)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar la información del pago?
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={handleRestartPaid}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsRestartPaid(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Box>
  );
};

export default FormRecibos;
