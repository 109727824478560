import { Card, Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkRootAccess } from '../../modulos/modules.helpers';
import {
  getAllZonasRestauranteAction,
  setActivoZonasRestauranteAction,
} from './ZonasRestauranteAction';
import FormZonasRestaurante from './components/FormZonasRestaurante';
import ListadoZonasRestaurante from './components/ListadoZonasRestaurante';

const ZonasRestauranteView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const zonasRestauranteStore = Redux.useSelector(
    (state) => state.zonasRestaurante
  );

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Card sx={{ p: 2 }}>
          <BotonNuevoComponent
            click={async () => {
              await setActivoZonasRestauranteAction(dispatch, null);
              setOpenModalForm(true);
            }}
            azul
            mr={2}
          />
          {zonasRestauranteStore.all.length > 0 && (
            <BotonNuevoComponent
              click={async () => {
                await setActivoZonasRestauranteAction(dispatch, null);
                await getAllZonasRestauranteAction(
                  dispatch,
                  entidadesStore.activo.id
                );
              }}
              disabled={zonasRestauranteStore.loadingGetAll}
              white
              text={
                zonasRestauranteStore.loadingGetAll
                  ? 'Actualizando...'
                  : 'Actualizar'
              }
            />
          )}
        </Card>
      </Grid>
      <Grid item md={12}>
        <ListadoZonasRestaurante
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormZonasRestaurante"
        title={`${
          zonasRestauranteStore.activo ? 'Editar' : 'Nuevo'
        } zona de restaurante`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {zonasRestauranteStore.activo ? (
          <Typography variant="h6">
            {zonasRestauranteStore.activo.nombres}{' '}
            {zonasRestauranteStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormZonasRestaurante
          activo={zonasRestauranteStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default ZonasRestauranteView;
