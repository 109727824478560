import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const methodsPayApi = `${baseApi}/payment-methods`;

export const getAllMetodosPagoService = async (params) => {
  const res = await axios.get(methodsPayApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveMetodosPagoService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${methodsPayApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${methodsPayApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneMetodosPagoService = async (id) => {
  const res = await axios.get(`${methodsPayApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteMetodosPagoService = async (id) => {
  const res = await axios.delete(`${methodsPayApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
