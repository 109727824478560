import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';
import { serialize } from 'object-to-formdata';

export const serviciosApi = `${baseApi}/services`;

export const getAllServiciosService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(serviciosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveServiciosService = async (id, data) => {
  if (data.id) delete data.id;
  const isFile = data.image || data.newAboutImages?.length;

  if (!data.newAboutImages?.length) {
    delete data.newAboutImages;
  }
  if (!!isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
      indices: true,
    })
  }

  // if (data.image || data.newAboutImages?.length) {
  //   const formData = new FormData();

  //   if (data.newAboutImages.length) {
  //     for (let i = 0; i < data.newAboutImages.length; i++) {
  //       formData.append(`newAboutImages`, data.newAboutImages[i])
  //     }
  //     delete data.newAboutImages;
  //   }

  //   if (data.deletedAboutImages && data.deletedAboutImages.length) {
  //     for (let i = 0; i < data.deletedAboutImages.length; i++) {
  //       formData.append(`deletedAboutImages[]`, data.deletedAboutImages[i])
  //     }
  //     delete data.deletedAboutImages;
  //   }

  //   if (data.schedules && data.schedules.length) {
  //     for (let i = 0; i < data.schedules.length; i++) {
  //       const schedule = data.schedules[i];
  //       formData.append(`schedules[${i}][day]`, schedule.day);
  //       if (schedule.schedules && schedule.schedules.length) {
  //         for (let ii = 0; ii < schedule.schedules.length; ii++) {
  //           const time = schedule.schedules[ii];
  //           formData.append(`schedules[${i}][schedules][${ii}]`, time);
  //         }
  //       }
  //     }
  //     delete data.schedules;
  //   } else {
  //     formData.append('schedules[]', '');
  //   }

  //   Object.keys(data).forEach((key) => {
  //     formData.append(key, data[key]);
  //   });
  //   data = formData;
  // } else {
  //   delete data.image;
  // }

  let res;
  if (id) {
    res = await axios.put(`${serviciosApi}/${id}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  } else {
    res = await axios.post(serviciosApi, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  }
  return res.data;
};

export const getOneServiciosService = async (id) => {
  const res = await axios.get(`${serviciosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteServiciosService = async (id) => {
  const res = await axios.delete(`${serviciosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
