import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import ButtonFile, {
  acceptOnlyImages,
} from '../../../../components/ButtonFile';
import LoadingComponent from '../../../../components/Loading';
import AreaCategoriasField from '../../../../components/fields/AreaCategoriasField';
import * as CONST from '../../../../constants/FieldsConst';
import { DAY, HOUR_BLOCK } from '../../../../constants/enumsConst';
import { dateformat } from '../../../../helpers';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { saveRestaurantesAction } from '../RestaurantesAction';

import { Checkbox, FormControlLabel } from '@mui/material';

import { Edit, Remove } from '@mui/icons-material';
import { Modal } from '../../../../components';
import { useNotification } from '../../../../helpers/notification';
import { baseApi, token } from '../../../../services/Apis';
import {
  CustomTabPanel,
  a11yProps,
} from '../../../subareas/components/FormSubAreas';
import FormAreaExtra from './FormExtras';
import RestaurantTypesField from '../../../../components/fields/RestaurantTypesField';
import Schedules from '../../../contactos/contactos/components/Schedules';
import ButtonFileMultipleImages from '../../../../components/FieldFileMultilpleImages';
import { min } from 'moment-timezone';
import useFetch from 'http-react';

const getTypes = async (entityId) => {
  const { data } = await axios.get(`${baseApi}/restaurant-types`, {
    headers: await token({ isToken: true }),
    params: {
      active: true,
      entityId
    },
  });
  return data.data;
};

const FormRestaurantes = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);

  const [fileImage, setFileImage] = React.useState();
  const [fileMenu, setFileMenu] = React.useState();
  const [addExtraModal, setAddExtraModal] = React.useState(false);
  
  const schedules = ['08:00:00 - 18:00:00'];
  const [restaurant, setRestaurant] = React.useState({
    [CONST.NAME]: '',
    averagePrice: 15,
    services: ['wifi'],
    paymentMethods: ['TRANSFER', 'MOBILE', 'ZELLE', 'CASH_BS', 'CASH_US', 'POS'],
    [CONST.SCHEDULES]: [
      { day: 'monday', schedules },
      { day: 'tuesday', schedules },
      { day: 'wednesday', schedules },
      { day: 'thursday', schedules },
      { day: 'friday', schedules },
      { day: 'saturday', schedules: ['08:00:00 - 14:00:00'] },
      { day: 'sunday', schedules: ['08:00:00 - 14:00:00'] }
    ],
    interval: 90,
    deletedAboutImages: [],
    newAboutImages: [],
    aboutImages: [],
    active: true,
    minPersons: 1,
    maxPersons: 5,
    capacity: 30,
    waitlistSendInterval: 5,
    waitBlockTimeLimit: 20,
    zoneIds: [],
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    averagePrice: false,
    services: false,
    paymentMethods: false,
    waitlistSendInterval: false,
    waitBlockTimeLimit: false,
    zoneIds: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingCategorias, setLoadingCategorias] = React.useState(false);
  const [types, setTypes] = React.useState([]);
  const [tabs, setTabs] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingCategorias(true);
      const types = await getTypes(entidadesStore.activo.id);
      if (types.length) {
        setRestaurant({ ...restaurant, typeId: types[0].id });
        setTypes(types);
      }
      setLoadingCategorias(false);

      
      if (restaurantesStore.activo === null) return;
      let data = {};
      Object.keys(restaurant).map((key) => (data[key] = restaurantesStore.activo[key]));
      console.log(restaurantesStore.activo)
      data = {
        ...data,
        deletedAboutImages: [],
        typeId: restaurantesStore.activo.type.id,
        zoneIds: restaurantesStore.activo.zones.map((zone) => zone.id),
      };

      setRestaurant(data);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (restaurantesStore.activo === null) return;
    let data = {};
    Object.keys(restaurant).map((key) => (data[key] = restaurantesStore.activo[key]));
    data = {
      ...data,
      deletedAboutImages: [],
      typeId: restaurantesStore.activo.type.id,
      zoneIds: restaurantesStore.activo.zones.map((zone) => zone.id),
    
    };
    console.log(data);

    setRestaurant(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantesStore.activo]);

  const _handleSetDataFieldArea = (key, value) =>
    setRestaurant({ ...restaurant, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = restaurant[key];
      const valid = val === null || val === '';
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    console.log('asd')
    if (valid) {
      const data = {
        ...restaurant,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        // [CONST.DAYS_PREV_FOR_RESERVE]: Number(restaurant.daysPrevForReserve),
        // [CONST.ENTITY_ID]: entidadesStore.activo.id,
        // [CONST.OPENING_TIME]: dateformat(restaurant.openingTime, {
        //   complete: true,
        //   isHHmmss: true,
        // }),
        // [CONST.CLOSING_TIME]: dateformat(restaurant.closingTime, {
        //   complete: true,
        //   isHHmmss: true,
        // }),
      };
      if (fileImage) data[CONST.IMAGE] = fileImage;
      if (fileMenu) data.menu = fileMenu;
      setLoadingSave(true);
      let res = await saveRestaurantesAction(
        { dispatch, addNotification },
        restaurantesStore.activo === null ? null : restaurantesStore.activo.id,
        data,
        restaurantesStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  const onTabs = (event, newValue) => {
    setTabs(newValue);
  };

  const { data: zonesData, loading: loadingZones } = useFetch(
    `${baseApi}/restaurant-zones/short`,
    {
      headers: token({}),
      query: {
        active: true,
      },
    }
  );
  const zones = zonesData && !zonesData.errors ? zonesData : [];

  // const tabPanel1 = <TabPanel value={1}>
  const infoForm = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <ButtonFile
          id={CONST.IMG_FOTO}
          onFile={(file) => setFileImage(file)}
          file={fileImage}
          text="Subir imagén"
          sxc={{ marginTop: '5px' }}
          size="60%"
          name={restaurantesStore.activo ? restaurantesStore.activo.name : null}
          onDelete={() => setFileImage(null)}
          accept={acceptOnlyImages}
          src={
            fileImage
              ? null
              : restaurantesStore.activo
              ? restaurantesStore.activo.imageUrl
              : null
          }
        />
        <Grid item xs={12} md={12}>
          <ButtonFile
            id='menu'
            onFile={(file) => setFileMenu(file)}
            file={fileMenu}
            text="Subir el menu actual"
            sxc={{ marginTop: '5px' }}
            size="50%"
            name={restaurantesStore.activo ? restaurantesStore.activo.name : null}
            onDelete={() => setFileMenu(null)}
            accept={'application/pdf'}
            src={
              fileMenu
                ? null
                : restaurantesStore.activo
                ? restaurantesStore.activo.menu
                : null
            }
          />
          <Grid item xs={12} md={12}>
            <ButtonFileMultipleImages
              images={restaurant.aboutImages}
              onChange={(files) => {
                setRestaurant({ ...restaurant, newAboutImages: files });
              }}
              onDelete={(image) => {
                console.log(image);
                console.log(restaurant);
                restaurant.deletedAboutImages.push(image);
                // console.log(');
                setRestaurant({
                  ...restaurant,
                });
                // setRestaurant({
                //   ...restaurant,
                //   // deletedAboutImages: [...restaurant.deletedAboutImages, image],
                // });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              id={CONST.NAME}
              required
              label="Nombre"
              value={restaurant.name}
              onChange={(e) =>
                _handleSetDataFieldArea(CONST.NAME, e.target.value)
              }
              helperText={dataError.name ? validFielddHelper(1) : null}
              error={dataError.name}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box sx={{ mb: 1 }}>
              <LoadingComponent isLoading={loadingCategorias} text="none" />
            </Box>
            {
              loadingCategorias ? null : (
                <Grid item xs={12} md={12}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="selectUserType">
                      Tipo de restaurante
                    </InputLabel>
                    <Select
                      labelId="typeId"
                      id='typeId'
                      value={restaurant.typeId}
                      label="Tipo de restaurante"
                      onChange={(e) => {
                        setRestaurant({ ...restaurant, typeId: e.target.value });
                      }}
                    >
                      {
                        types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
          </Grid>
          
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              size="small"
              type="number"
              id='averagePrice'
              required
              label="Precio promedio"
              value={restaurant.averagePrice}
              onChange={(e) =>{
                setRestaurant({ ...restaurant, averagePrice: e.target.value });
              }}
              helperText={dataError.averagePrice ? validFielddHelper(1) : null}
              error={dataError.averagePrice}
            />
          </Grid>

          
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="selectUserType">
                Servicios
              </InputLabel>
              <Select
                labelId="services"
                id='services'
                multiple={true}
                value={restaurant.services}
                label="Servicios"
                onChange={(e) => {
                  setRestaurant({ ...restaurant, services: e.target.value });
                }}
              >
                <MenuItem value="wifi">Wifi</MenuItem>
                <MenuItem value="parking">Estacionamiento</MenuItem>
                <MenuItem value="catering">Catering</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="selectUserType">
                Zonas
              </InputLabel>
              <Select
                labelId="zoneIds"
                id='zoneIds'
                multiple={true}
                value={restaurant.zoneIds}
                label="Zonas"
                onChange={(e) => {
                  setRestaurant({ ...restaurant, zoneIds: e.target.value });
                }}
              >
              {
                zones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>{zone.name}</MenuItem>
                ))
              }
                {/* <MenuItem value="Terraza">Terraza</MenuItem>
                <MenuItem value="Interior">Interior</MenuItem>
                <MenuItem value="Exterior">Exterior</MenuItem>
                <MenuItem value="Café">Café</MenuItem>
                <MenuItem value="Bar">Bar</MenuItem>
                <MenuItem value="Salón">Salón</MenuItem>
                <MenuItem value="VIP">VIP</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="selectUserType">
                Métodos de pago
              </InputLabel>
              <Select
                labelId="paymentMethods"
                id='paymentMethods'
                multiple={true}
                value={restaurant.paymentMethods}
                label="Métodos de pago"
                onChange={(e) => {
                  setRestaurant({ ...restaurant, paymentMethods: e.target.value });
                }}
              >
                <MenuItem value="TRANSFER">Transferencia</MenuItem>
                <MenuItem value="MOBILE">Pago móvil</MenuItem>
                <MenuItem value="ZELLE">ZELLE</MenuItem>
                <MenuItem value="CASH_BS">Efectivo en Bolívares</MenuItem>
                <MenuItem value="CASH_US">Efectivo en Dólares</MenuItem>
                <MenuItem value="POS">Punto de Venta</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-estatus-label">Estatus</InputLabel>
              <Select
                labelId="select-estatus-label"
                size="small"
                label="Estatus"
                value={restaurant.active}
                onChange={(e) =>
                  _handleSetDataFieldArea(CONST.ACTIVE, e.target.value)
                }
                id={CONST.ACTIVE}
              >
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Bloqueado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const validacionesForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
          <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Tiempo de reserva (intervalo de tiempo)
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={restaurant.interval}
                  onChange={(e) =>{
                    setRestaurant({ ...restaurant, interval: e.target.value });
                  }}
                  id='interval'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.interval ? validFielddHelper(1) : null
                  }
                  error={dataError.interval}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Mínimo de personas por reserva
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={restaurant.minPersons}
                  onChange={(e) =>{
                    setRestaurant({ ...restaurant, minPersons: e.target.value });
                  }}
                  id='minPersons'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.minPersons ? validFielddHelper(1) : null
                  }
                  error={dataError.minPersons}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Máximo de personas por reserva
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={restaurant.maxPersons}
                  onChange={(e) =>{
                    setRestaurant({ ...restaurant, maxPersons: e.target.value });
                  }}
                  id='maxPersons'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.maxPersons ? validFielddHelper(1) : null
                  }
                  error={dataError.maxPersons}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} md={8}>
                <Typography variant="body">
                  Capacidad máxima
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={restaurant.capacity}
                  onChange={(e) =>{
                    setRestaurant({ ...restaurant, capacity: e.target.value });
                  }}
                  id='capacity'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={
                    dataError.capacity ? validFielddHelper(1) : null
                  }
                  error={dataError.capacity}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Typography variant="body">
                    Tiempo limite para bloques de espera (minutos)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    type="number"
                    value={restaurant.waitBlockTimeLimit}
                    onChange={(e) =>
                      _handleSetDataFieldArea(
                        'waitBlockTimeLimit',
                        Number(e.target.value)
                      )
                    }
                    id={'waitBlockTimeLimit'}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    helperText={
                      dataError.waitBlockTimeLimit ? validFielddHelper(1) : null
                    }
                    error={dataError.waitBlockTimeLimit}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Typography variant="body">
                    Tiempo de espera para enviar notificación en cola a miembros
                    de lista de espera (segundos)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    type="number"
                    value={restaurant.waitlistSendInterval}
                    onChange={(e) =>
                      _handleSetDataFieldArea(
                        'waitlistSendInterval',
                        Number(e.target.value)
                      )
                    }
                    id={'waitlistSendInterval'}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    helperText={
                      dataError.waitlistSendInterval ? validFielddHelper(1) : null
                    }
                    error={dataError.waitlistSendInterval}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Schedules
                schedules={restaurant.schedules}
                is24h={true}
                onChange={(e) => {
                  setRestaurant({ ...restaurant, schedules: e });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const mapaForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <ButtonFile
          id='menu'
          onFile={(file) => setFileMenu(file)}
          file={fileMenu}
          text="Subir el menu actual"
          sxc={{ marginTop: '5px' }}
          size="50%"
          name={restaurantesStore.activo ? restaurantesStore.activo.name : null}
          onDelete={() => setFileMenu(null)}
          accept={'application/pdf'}
          src={
            fileMenu
              ? null
              : restaurantesStore.activo
              ? restaurantesStore.activo.menu
              : null
          }
        />
        <Grid item xs={12} md={12}>
          <ButtonFileMultipleImages
            images={restaurant.aboutImages}
            onChange={(files) => {
              setRestaurant({ ...restaurant, newAboutImages: files });
            }}
            onDelete={(image) => {
              setRestaurant({
                ...restaurant,
                deletedAboutImages: [...restaurant.deletedAboutImages, image],
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box component="form" sx={{}}>
      <LoadingComponent
        isLoading={loadingSave}
        text={restaurantesStore.activo ? 'Actualizando...' : 'Guardando...'}
      />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabs} onChange={onTabs} aria-label="basic-tabs-area">
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Validaciones" {...a11yProps(1)} />
              {/* <Tab label="Menu y imágenes" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={tabs} index={0}>
            {infoForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={1}>
            {validacionesForm}
          </CustomTabPanel>
          {/* <CustomTabPanel value={tabs} index={2}>
            {mapaForm}
          </CustomTabPanel> */}
        </Grid>
        <Grid item md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

FormRestaurantes.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormRestaurantes;
