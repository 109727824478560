import {
  CLEAR_ALL,
  GETALL_ENT_DESTACADAS,
  LOADING_GETALL_ENT_DESTACADAS,
  PARAMS_ENT_DESTACADAS,
  SET_ACTIVO_ENT_DESTACADAS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  params: {
    date: null,
    perMonth: true,
  },
  loadingGetAll: false,
};

export const entDestacadasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ENT_DESTACADAS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_ACTIVO_ENT_DESTACADAS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_ENT_DESTACADAS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_ENT_DESTACADAS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
