import React from "react"
import { Box, Typography, Rating, Paper } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"

const BannerPreview = ({ title, description, location, rating = 4.0, bannerImage }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        maxWidth: 400,
        height: 400,
        borderRadius: 4,
        overflow: "hidden",
        position: "relative",
        boxShadow: 3,
        mb: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: bannerImage
            ? `url(${typeof bannerImage === "string" ? bannerImage : URL.createObjectURL(bannerImage)})`
            : "url(/placeholder.svg?height=400&width=400)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 70%, transparent 100%)",
            padding: 2,
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <LocationOnIcon sx={{ mr: 0.5, fontSize: 20 }} />
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {location || "UBICACIÓN"}
            </Typography>
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            {title || "Título de la Entidad"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Rating value={rating} readOnly precision={0.5} size="small" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {rating.toFixed(1)}
            </Typography>
          </Box>
          <Typography variant="body2">{description || "Descripción de la entidad destacada"}</Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default BannerPreview

