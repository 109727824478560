import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const notificationsApi = `${baseApi}/notifications`;

export const getAllNotificacionesService = async (params) => {
  const res = await axios.get(notificationsApi, {
    headers: await token({}),
    params: {
      ...params,
      sortDirection: 'DESC',
    },
  });
  return res;
};

export const saveNotificacionesService = async (id, data) => {
  if (data.file) {
    const formData = new FormData();

    if (data.userTypes?.length) {
      for (const userType of data.userTypes) {
        formData.append('userTypes[]', userType);
      }
      delete data.userTypes;
    }
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.file;
  }

  let res;
  if (id) {
    res = await axios.put(`${notificationsApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.file,
      }),
    });
  } else {
    res = await axios.post(`${notificationsApi}`, data, {
      headers: await token({
        isFormData: !!data.file,
      }),
    });
  }
  return res.data;
};

export const getOneNotificacionesService = async (id) => {
  const res = await axios.get(`${notificationsApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteNotificacionesService = async (id) => {
  const res = await axios.delete(`${notificationsApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};


export const sendNotificacionesService = async (data) => {
  const res = await axios.post(`${notificationsApi}/send`, data, {
    headers: await token({}),
  });
  return res;
};
