import UserIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  colors,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useFetch from 'http-react';
import React from 'react';
import * as Redux from 'react-redux';
import { MORADO_COLOR } from '../constants/ColorsConst';
import { getDateTimezone } from '../helpers';
import { useNotification } from '../helpers/notification';
import { deleteReservasService } from '../services';
import { baseApi, token } from '../services/Apis';
import LoadingComponent from './Loading';
import Modal from './modals/Modal';

export default function ReservationUsedBlocks({
  areaIds = [],
  dates = [],
  refresh = false,
  interactive = false,
  allDates = false,
  onlyReservations = false,
  userCodeId = null,
}) {
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [date, setDate] = React.useState(
    allDates ? dayjs().startOf('day').toDate() : dates[0] || null
  );

  const [areaId, setAreaId] = React.useState(null);

  // const selectedBlock = blocks.find((el) => el.id === areaId);
  const { timezone } = entidadesStore.activo.country;
  const { addNotification } = useNotification();

  const [viewModalBlock, setViewModalBlock] = React.useState(null);

  const { data: subareas, reFetch } = useFetch(
    `${baseApi}/reservations/by-area`,
    {
      headers: token({}),
      query: {
        areaId,
        date: getDateTimezone(
          dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
          timezone
        ),
        userCodeId: userCodeId || undefined,
      },
      onResolve: (data) => {},
    }
  );

  const { data: areaData, loading: loadingAreas } = useFetch(
    `${baseApi}/areas/short`,
    {
      headers: token({}),
      query: {
        entityId: entidadesStore.activo.id,
      },
    }
  );

  const areas = areaData?.filter((el) => areaIds.includes(el.id)) || [];

  const onSelectBlock = (block, subarea) => {
    setViewModalBlock({ block, subarea });
  };

  React.useEffect(() => {
    if (areaIds.length > 0) {
      setAreaId(areaIds[0]);
    }
  }, [areaIds, date]);

  React.useEffect(() => {
    // console.log('refresh', refresh);
    if (refresh) {
      reFetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  React.useEffect(() => {
    if (dates.length > 0) {
      setDate(dates[0]);
    }
  }, [dates]);

  const handleDeleteReservation = async (single = true) => {
    try {
      await deleteReservasService(viewModalBlock.block.reservation.id, {
        single,
      });
      setViewModalBlock(null);
      reFetch();
      addNotification('Reserva eliminada');
    } catch (error) {
      addNotification(
        error.response?.data?.message ?? 'Error al eliminar la reserva',
        {
          warning: true,
        }
      );
    }
  };

  return (
    <Grid container sx={{ paddingTop: 2 }}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 20 }}>
            Tablero de bloques
          </Typography>
          <div>
            {areaIds.length > 1 && (
              <FormControl sx={{ minWidth: '250px' }}>
                <InputLabel id="area">Área</InputLabel>
                <Select
                  size="small"
                  value={areaId}
                  label="Área"
                  onChange={(e) => setAreaId(e.target.value)}
                >
                  {areas.map((area) => (
                    <MenuItem key={area.id} value={area.id}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {(allDates || dates.length > 1) && (
              <FormControl sx={{ minWidth: '250px' }}>
                <LocalizationProvider
                  adapterLocale={'es'}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    label={'Fecha'}
                    value={date}
                    openTo="day"
                    size="small"
                    adapterLocale={'ve'}
                    shouldDisableDate={(date) =>
                      allDates
                        ? undefined
                        : !dates.find((el) => dayjs(el).isSame(date, 'day'))
                    }
                    onChange={(date) => {
                      setDate(date);
                    }}
                    views={['day']}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" required {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )}
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12}>
        <LoadingComponent
          isLoading={loadingAreas}
          text="Cargando bloques de horas..."
        />
      </Grid>

      <Grid item xs={12} md={12}>
        {subareas?.length > 0 ? (
          <Box sx={{ margin: 'auto', paddingTop: 2 }}>
            {/* <Typography variant="h3" component="h1" gutterBottom>
              Reservas de {selectedBlock?.name}
            </Typography> */}
            <Grid container spacing={3}>
              {subareas?.map((subarea) => (
                <Grid item xs={12} md={12} lg={6} key={subarea.name}>
                  <Card>
                    <CardContent>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 20,
                          fontWeight: 'bold',
                          color: colors.grey[700],
                        }}
                      >
                        {subarea.name}
                      </Typography>

                      <Divider sx={{ marginBottom: 3 }} />

                      <Grid container spacing={1}>
                        {subarea.blocks.map((block, idx) => {
                          const isAvailable = !block.reservation;
                          let isShared = false;

                          if (
                            block.reservation &&
                            block.reservation.userCode &&
                            subarea.maxPersonsShared > 1 &&
                            subarea.maxPersonsShared -
                              block.reservation.personsShared >
                              0
                          ) {
                            isShared = true;
                          }

                          const isDisabled =
                            !interactive ||
                            !block.reservation ||
                            (onlyReservations &&
                              block?.reservation?.type === 'block');

                          return (
                            <Button
                              style={{
                                padding: 0,
                                cursor: isDisabled ? 'default' : 'pointer',
                              }}
                              onClick={() => onSelectBlock(block, subarea)}
                              disabled={isDisabled}
                            >
                              <Chip
                                label={
                                  <Box>
                                    <Typography
                                      variant="caption"
                                      display="block"
                                    >
                                      {dayjs(block.entryTime)
                                        .tz(timezone)
                                        .format('hh:mm A')}
                                    </Typography>
                                    {block.reservation && (
                                      <div className="reservation">
                                        {block.reservation.description && (
                                          <div className="description">
                                            {block.reservation.description
                                              .length > 13
                                              ? `${block.reservation.description.slice(
                                                  0,
                                                  13
                                                )}...`
                                              : block.reservation.description}
                                          </div>
                                        )}
                                        {block.reservation.userCode && (
                                          <>
                                            {subarea.maxPersonsShared > 1 ? (
                                              <>
                                                {subarea.maxPersonsShared -
                                                  block.reservation
                                                    .personsShared >
                                                0 ? (
                                                  <div
                                                    className="user"
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <UserIcon
                                                      fontSize="small"
                                                      sx={{ mr: '2px' }}
                                                    />
                                                    Disponible:{' '}
                                                    {subarea.maxPersonsShared -
                                                      block.reservation
                                                        .personsShared}
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="user"
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <UserIcon
                                                      fontSize="small"
                                                      sx={{ mr: '2px' }}
                                                    />
                                                    Agotado
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div
                                                className="user"
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <UserIcon
                                                  fontSize="small"
                                                  sx={{ mr: '2px' }}
                                                />
                                                {
                                                  block.reservation.userCode
                                                    .code.code
                                                }
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {/* {reservation.info && (
                                        <Typography variant="caption" display="block">
                                          {reservation.info}
                                        </Typography>
                                      )} */}
                                  </Box>
                                }
                                color={
                                  isShared
                                    ? 'secondary'
                                    : isAvailable
                                    ? 'primary'
                                    : 'error'
                                }
                                sx={{
                                  width: '130px',
                                  height: '55px',
                                  textAlign: 'center',
                                  margin: '4px',
                                  '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    padding: '8px 0',
                                  },
                                }}
                              />
                            </Button>
                          );
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', padding: 2 }}>
            No hay bloques disponibles
          </Typography>
        )}
      </Grid>

      {viewModalBlock && (
        <Modal
          open={!!viewModalBlock}
          onClose={() => setViewModalBlock(null)}
          maxWidth="sm"
          title={
            <div>
              {`${viewModalBlock.subarea.name} - ${dayjs(
                viewModalBlock.block.entryTime
              ).format('hh:mm A')}`}
              <Chip
                label={
                  viewModalBlock.block.reservation.event
                    ? 'Evento'
                    : viewModalBlock.block.reservation.type === 'block'
                    ? 'Bloqueo'
                    : 'Reserva'
                }
                style={{
                  color: '#fff',
                  backgroundColor: viewModalBlock.block.reservation.event
                    ? '#FF9800'
                    : viewModalBlock.block.reservation.type === 'block'
                    ? '#F44336'
                    : '#4CAF50',
                  marginLeft: '10px',
                }}
              />
            </div>
          }
        >
          <DialogContent>
            {viewModalBlock.block.reservation.type === 'block' && (
              <>
                <Typography variant="h6">
                  {viewModalBlock.block.reservation.description ?? '-'}
                </Typography>
              </>
            )}

            {viewModalBlock.block.reservation.type === 'reservation' && (
              <>
                <Typography variant="h6">
                  Nombre:{' '}
                  {
                    viewModalBlock.block.reservation.userCode.user.profile
                      .firstName
                  }{' '}
                  {
                    viewModalBlock.block.reservation.userCode.user.profile
                      .lastName
                  }
                </Typography>
                <Typography variant="h6">
                  Código: {viewModalBlock.block.reservation.userCode.code.code}
                </Typography>
                {/* {
                      viewModalBlock.block.reservation.areaExtras.length > 0 && (
                        <>
                          <Typography variant="h6">
                            Extras:
                          </Typography>

                          <ul style={{ paddingLeft: '20px', listStyle: 'none', margin: 0 }}>
                            {
                              viewModalBlock.block.reservation.areaExtras
                              .filter((el) => el.quantity > 0)
                              .map((areaExtra) => (
                                <li key={areaExtra.id}>
                                  {areaExtra.quantity} {areaExtra.extra.name} ${areaExtra.price * areaExtra.quantity}
                                </li>
                              ))
                            }
                          </ul>
                        </>
                      )
                    } */}

                {viewModalBlock.block.reservation.payment && (
                  <Typography variant="h6">
                    Total: ${viewModalBlock.block.reservation.payment.amount}
                  </Typography>
                )}
              </>
            )}
          </DialogContent>

          <DialogActions sx={{ padding: 2 }}>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setViewModalBlock(null)}
            >
              CANCELAR
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                handleDeleteReservation();
              }}
            >
              ELIMINAR
            </Button>

            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                handleDeleteReservation(false);
              }}
            >
              ELIMINAR BlOQUES
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
}
