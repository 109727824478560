import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Divider, IconButton, List, Toolbar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import * as Redux from 'react-redux';
import { MORADO_COLOR } from '../../../constants/ColorsConst';
import { ADMIN, SU } from '../../../constants/ConfigConst';
import { MANAGER } from '../../../constants/enumsConst';
import { checkModuleAccess } from '../../../modules/modulos/modules.helpers';
import * as ROUTES from '../../../router/Routes';
import InfoEntidadComponent from './InfoEntidad';
import ItemDrawer from './ItemDrawer';
import LogOnComponent from './Logon';

export const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const getRandomNumber = () => {
  return Math.floor(Math.random() * (9999999999 - 1000000000)) + 1000000000;
};

const DrawerTemplate = ({ open, toggleDrawer }) => {
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  const isAdmin = loginStore.roles.indexOf(ADMIN) !== -1;
  const isActivoEntidad = entidadesStore.activo !== null && (isRoot || isAdmin);

  // const modules = loginStore.modules;
  const checkModule = (module) => {
    return checkModuleAccess(module, loginStore);
  };

  const isManager = loginStore.user.userCodes.some(
    (el) => el.userType === MANAGER
  );

  const tiposVistas = [];

  if (isRoot || checkModule('contact'))
    tiposVistas.unshift(ROUTES.tiposContactos);
  if (isRoot || checkModule('document'))
    tiposVistas.unshift(ROUTES.tiposDocumentos);
  if (isRoot || checkModule('invitation'))
    tiposVistas.unshift(ROUTES.tiposInvitacion);
  if (isRoot || checkModule('events')) tiposVistas.unshift(ROUTES.tiposEventos);
  if (isRoot || checkModule('typesImageEvents'))
    tiposVistas.unshift(ROUTES.tiposImagenClases);
  if (isRoot || checkModule('classes')) tiposVistas.unshift(ROUTES.tiposClases);
  if (isRoot || checkModule('typesLevelClasses'))
    tiposVistas.unshift(ROUTES.tiposNivelClases);
  if (isRoot || checkModule('restaurantes')) {
    tiposVistas.unshift(ROUTES.tiposRestaurante);
    tiposVistas.unshift(ROUTES.zonasRestaurante)
  }
  if (isRoot || checkModule('typesLevelCode'))
    tiposVistas.unshift(ROUTES.tiposNivelCode);
  if (isRoot || checkModule('typesAreas'))
    tiposVistas.unshift(ROUTES.tiposAreas);
  if (isRoot || checkModule('typesUsers'))
    tiposVistas.unshift(ROUTES.tiposUsuarios);

  // if (isRoot || checkModule('methodsPay'))
  //   tiposVistas.unshift(ROUTES.metodosPago);

  const lista = (
    <List component="nav" sx={{ width: '100%', mx: 0.5 }}>
      {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.panelControl}
          openDrawer={open}
        />
      )}
      {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={{
            ...ROUTES.todos,
            vistas: [
              ROUTES.banks,
              ROUTES.entidades,
              ROUTES.notificaciones,
              ROUTES.users,
              ROUTES.appModules,
              ROUTES.apikeys,
              ROUTES.metodosPago,
              ROUTES.entDestacadas,
              // ROUTES.referidos,
            ],
          }}
          openDrawer={open}
        />
      )}
      {/* {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.banks}
          openDrawer={open}
        />
      )} */}
      {/* {(isRoot || entities.length > 1) && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.entidades}
          openDrawer={open}
        />
      )} */}
      {/* {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.users}
          openDrawer={open}
        />
      )} */}
      {/* {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.appModules}
          openDrawer={open}
        />
      )} */}
      {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={{
            ...ROUTES.crm,
            vistas: [ROUTES.tickets, ROUTES.usersDelete],
          }}
          openDrawer={open}
        />
      )}
      {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={{
            ...ROUTES.servicios,
            vistas: [ROUTES.servicios, ROUTES.tiposServicios],
          }}
          openDrawer={open}
        />
      )}
      {isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.settings}
          openDrawer={open}
        />
      )}

      {/* ENTIDADES */}
      {checkModule('accessDoor') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.puertas}
          openDrawer={open}
        />
      )}

      {checkModule('code') &&
        isActivoEntidad &&
        entidadesStore.activo.entityType.id === 2 && (
          <ItemDrawer
            key={getRandomNumber()}
            item={ROUTES.acciones}
            openDrawer={open}
          />
        )}

      {!isManager && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.ajustes}
          openDrawer={open}
        />
      )}

      {checkModule('accessLogs') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.accesos}
          openDrawer={open}
        />
      )}

      {checkModule('areas') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.areas}
          openDrawer={open}
        />
      )}

      {checkModule('banks') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.bancos}
          openDrawer={open}
        />
      )}

      {checkModule('reservation') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.reservasEspeciales}
          openDrawer={open}
        />
      )}

      {isActivoEntidad &&
        [3, 4].includes(entidadesStore.activo.entityType.id) && (
          <ItemDrawer
            key={getRandomNumber()}
            item={ROUTES.codigos}
            openDrawer={open}
          />
        )}

      {checkModule('classes') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.clases}
          openDrawer={open}
        />
      )}

      {checkModule('contact') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.contactos}
          openDrawer={open}
        />
      )}

      {isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.dashboard}
          openDrawer={open}
        />
      )}

      {checkModule('delivery') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.delivery}
          openDrawer={open}
        />
      )}

      {checkModule('discounts') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.descuentos}
          openDrawer={open}
        />
      )}

      {checkModule('document') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.documentos}
          openDrawer={open}
        />
      )}

      {checkModule('events') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.eventos}
          openDrawer={open}
        />
      )}

      {checkModule('invitation') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.invitados}
          openDrawer={open}
        />
      )}

      {isActivoEntidad && isRoot && (
        <ItemDrawer
          key={getRandomNumber()}
          item={{
            ...ROUTES.iots,
            vistas: [ROUTES.iotsQr, ROUTES.iotTuneles],
          }}
          openDrawer={open}
        />
      )}

      {!isManager && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.modulos}
          openDrawer={open}
        />
      )}

      {checkModule('payment') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.recibos}
          openDrawer={open}
        />
      )}

      {checkModule('code') &&
        isActivoEntidad &&
        entidadesStore.activo.entityType.id === 1 && (
          <ItemDrawer
            key={getRandomNumber()}
            item={ROUTES.residencias}
            openDrawer={open}
          />
        )}

      {checkModule('referral') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.referidos}
          openDrawer={open}
        />
      )}

      {checkModule('reservation') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.reservas}
          openDrawer={open}
        />
      )}

      {checkModule('restaurantes') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.restaurantes}
          openDrawer={open}
        />
      )}

      {checkModule('customPrice') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.preciosCustom}
          openDrawer={open}
        />
      )}

      {/* {checkModule('morosidad') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.morosidad}
          openDrawer={open}
        />
      )} */}

      {!!tiposVistas.length && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={{
            ...ROUTES.tipos,
            vistas: tiposVistas,
          }}
          openDrawer={open}
        />
      )}

      {checkModule('user') && isActivoEntidad && (
        <ItemDrawer
          key={getRandomNumber()}
          item={ROUTES.usuarios}
          openDrawer={open}
        />
      )}

      {/* {
                    menus.map((route) => {
                        const existe = vistas.find(v => v.route === route.to)
                        return existe || loginStore.user.perfil.id === 1 ? (
                            <ItemDrawer
                                key={getRandomNumber().title}
                                item={route}
                                openDrawer={open}
                            />
                        ) : (<></>)
                    })
                } */}
    </List>
  );

  return (
    <Drawer variant="permanent" anchor="left" open={open}>
      <Box>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
          }}
        >
          <InfoEntidadComponent />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
      </Box>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'relative',
          minHeight: '75%',
          '::-webkit-scrollbar': {
            width: '7px',
          },
          '::-webkit-scrollbar-track': {
            background: grey[300] /* color of the tracking area */,
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: MORADO_COLOR /* color of the scroll thumb */,
            borderRadius: '4px' /* roundness of the scroll thumb */,
          },
        }}
      >
        {lista}
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '15%',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <LogOnComponent open={open} isLogo />
      </Box>
    </Drawer>
  );
};

export default DrawerTemplate;
